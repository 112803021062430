import React, { FC, useCallback, useState } from 'react';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import { FormLabel, Select, TextField } from '@material-ui/core';
import { Stack } from '../../../components/common';
import Utils from '../../../utils/utils';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import { IconButton } from '../../../components/IconButton';
import { DateDisplay } from '../../incidents/date-picker-component/DateDisplay';
import DatePicker from 'react-datepicker';
import { TextArea } from '../../../components/TextArea';
import BackupOutlined from '@material-ui/icons/BackupOutlined';

import './NewTask.scss';
export type Task = {
  name: string;
};

const assignees = [
  {
    name: 'alice',
    label: 'Alice'
  },
  {
    name: 'drake',
    label: 'Drake Ramoray'
  },
  {
    name: 'cooper',
    label: 'Shelon Cooper'
  },
  {
    name: 'allen',
    label: 'Barry Allen'
  }
];

export const NewTask: FC<{
  task: any;
  open: boolean;
  setShowNewTaskDialog: (arg0: boolean) => void;
}> = (props) => {
  const [isOpen, setIsOpen] = useState(props.open);
  const [datePickerVisible, setDatePickerVisible] = useState(false);

  const [editName, setEditName] = useState(false);
  const [dueDate, setDueDate] = useState<Date>(new Date());
  const [newFile, setNewFile] = useState<string>('');
  // const [taskDetails, setTaskDetails] = useState<Task>({ name: '' });
  const [taskName, setTaskName] = useState('');
  const onEditName = useCallback(() => {
    console.log(editName);
    setEditName(!editName);
    console.log(editName);
  }, [editName]);
  const [hasError, setHasError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [inputFile, setInputFile] = useState<HTMLInputElement | null>();

  const closeModal = useCallback(() => {
    setIsOpen(false);
    props.setShowNewTaskDialog(false);
  }, [props]);

  const handleConfirm = useCallback(() => {
    //   onConfirm(data);
    closeModal();
  }, [closeModal]);

  const handleCancel = useCallback(() => {
    //   onCancel(data);
    closeModal();
  }, [closeModal]);
  const handleOnFocus = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (_event) => {
      const focusRelatedErrors = ['This field is required.'];

      if (focusRelatedErrors.includes(errorMessage)) {
        setHasError(false);
        setErrorMessage('');
      }
    },
    [errorMessage]
  );
  const handleOnBlur = useCallback<React.FocusEventHandler<HTMLInputElement>>(
    (event) => {
      if (!event.target.value.length) {
        setHasError(true);
        setErrorMessage('This field is required.');
      }
    },
    []
  );
  const onButtonClick = () => {
    // `current` points to the mounted file input element
    inputFile?.click();
  };
  const handleCustomClick = () => {
    setDatePickerVisible(true);
  };
  return (
    <div>
      <Dialog
        //   style={{ borderRadius: '10px' }}
        open={isOpen}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <h3 className="main-task-title">Create New Task</h3>
        <DialogContent style={{ width: 600 }}>
          <div>
            <Stack direction="vertical">
              <div style={{ display: 'flex' }}>
                {editName === false && (
                  <FormLabel className="task-title">
                    {taskName === '' && 'Type new task title'} {taskName}
                  </FormLabel>
                )}
                {editName === false && (
                  <IconButton
                    //   className="edit-button"
                    icon={EditOutlinedIcon}
                    onClick={onEditName}
                    size="sm"
                  ></IconButton>
                )}

                {editName === true && (
                  <TextField
                    required
                    size="small"
                    onFocus={handleOnFocus}
                    onBlur={handleOnBlur}
                    style={{ width: '30%', height: '32px' }}
                    error={!!hasError}
                    id="outlined-error-helper-text"
                    placeholder="Enter Task Name"
                    value={taskName}
                    helperText={errorMessage}
                    variant="outlined"
                    onChange={(e) => setTaskName(e.target.value)}
                  />
                )}
                {editName === true && (
                  <IconButton
                    //   className="edit-button"
                    icon={DoneOutlinedIcon}
                    onClick={onEditName}
                    size="sm"
                  ></IconButton>
                )}
              </div>
              <Stack
                align="center"
                justify="flex-end"
                style={{ width: 'fit-content', flex: 'auto' }}
                className="created"
              >
                <p>Created at {Utils.formatDate(new Date(), 'DD/MM/YY')} </p>
              </Stack>
              <Stack direction="horizontal" gap={32}>
                <Stack direction="vertical">
                  <FormLabel className="small-labels">Status</FormLabel>
                  <p className="status">Open</p>
                </Stack>
                <Stack direction="vertical">
                  <FormLabel className="small-labels">Due Date</FormLabel>
                  <DatePicker
                    onChange={(val: Date) => {
                      setDueDate(val);
                    }}
                    open={datePickerVisible}
                    onClickOutside={() => setDatePickerVisible(false)}
                    onInputClick={handleCustomClick}
                    customInput={
                      <Stack
                        direction="horizontal"
                        style={{
                          alignItems: 'center',
                          marginTop: '-3%',
                          marginLeft: '-10px',
                          background: '#FFFFFF',
                          border: '1px solid #ECECF2',
                          borderRadius: '6px',
                          paddingRight: '10px'
                        }}
                      >
                        <DateDisplay></DateDisplay>
                        {Utils.formatDate(dueDate, 'D MMM Y')}
                        {/* {dueDate} */}
                      </Stack>
                    }
                  />
                </Stack>
                <Stack direction="vertical">
                  <FormLabel className="small-labels">Assigned to</FormLabel>
                  <Select
                    style={{
                      height: 36,
                      fontSize: '14px',
                      background: 'rgba(237, 237, 237, 0.4)',
                      borderRadius: '4px'
                    }}
                    native
                    variant="outlined"
                    //   defaultValue={defaultValue}
                    //   onChange={onChange}
                  >
                    <option disabled value="">
                      Choose option
                    </option>

                    {assignees.map((selectOption, index) => (
                      <option
                        key={`${selectOption.name}-${index}`}
                        value={selectOption.name}
                        selected={selectOption.name === ''}
                      >
                        {selectOption.label}
                      </option>
                    ))}
                  </Select>
                </Stack>
              </Stack>
              <hr className="horizontal-line" />
              <FormLabel className="large-labels">Description</FormLabel>
              <TextArea
                style={{ width: '100%' }}
                className="description-box"
                placeholder="Write task description here"
              />
              <hr className="horizontal-line" />
              <FormLabel className="large-labels">Attachments</FormLabel>
              <Stack direction="horizontal" style={{ alignItems: 'center' }}>
                <IconButton
                  icon={BackupOutlined}
                  onClick={onButtonClick}
                  size="sm"
                ></IconButton>
                <FormLabel
                  style={{
                    fontSize: 16,
                    marginLeft: 10
                  }}
                >
                  {newFile ? newFile : 'Upload your attachment'}
                </FormLabel>
              </Stack>
              <input
                type="file"
                id="file"
                onChange={(val) => {
                  if (val) {
                    const fileName = val.target.value.substring(
                      val.target.value.lastIndexOf('\\') + 1
                    );
                    setNewFile(fileName);
                  }
                }}
                ref={(ref) => setInputFile(ref)}
                style={{ display: 'none' }}
              />
            </Stack>
          </div>
        </DialogContent>
        <DialogActions style={{ marginTop: 66 }}>
          <Button
            onClick={handleCancel}
            color="primary"
            className="cancel-button"
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            color="primary"
            autoFocus
            className="done-button"
          >
            Done
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
