import {
  differenceInDays,
  format,
  formatDistance,
  formatDistanceToNowStrict as dateFnsFormatDistanceToNowStrict
} from 'date-fns';
import moment from 'moment';
import toaster from 'toasted-notes';
import 'toasted-notes/src/styles.css';
import Constants from './constants';
import _ from 'lodash';
import { Customer, Role, User } from '../services/types';
import { useQuery } from 'react-query';
import IrisPortalService from '../services/IrisPortalService';
import { CACHE_KEYS, CACHE_TIME } from './cacheUtils';
import { CustomersWithLowestHealthScores } from '../components/home/types';
import React from 'react';
import * as Sentry from '@sentry/react';

class Utils {
  public static clearStorage() {
    window.localStorage.clear();
  }

  public static getCurrencyValue(value: number) {
    if (!_.isNumber(value)) {
      return '0';
    }

    return new Intl.NumberFormat('en', {
      style: 'currency',
      currency: 'USD',
      //@ts-ignore
      notation: 'compact'
    }).format(value);
  }

  public static formatDate(inputDate: Date, format: string) {
    return moment(inputDate).format(format);
  }

  public static formatDateToUTC(stringDate: string | Date) {
    return moment.utc(moment(stringDate)).format();
  }

  public static getObjectItem(key: string) {
    return JSON.parse(window.localStorage.getItem(key) || '{}');
  }

  public static saveObjectItem(key: string, value: any) {
    window.localStorage.setItem(key, JSON.stringify(value || {}));
  }

  public static saveCustomerItem(value: Customer) {
    window.localStorage.setItem(
      Constants.STORAGE_KEY.CUSTOMER,
      JSON.stringify(value || {})
    );
  }

  public static getUserItem() {
    return Utils.getObjectItem('user');
  }

  public static removeObjectItem(key: string) {
    window.localStorage.removeItem(key);
  }

  public static showError(message: string) {
    console.error(message);
    Utils.showNotify(Constants.GENERIC_ERROR_MESSAGE);
  }

  public static showUnknownError(e: unknown) {
    const err = Utils.ensureError(e);
    Utils.showError(err.message);
  }

  public static showNotify(message: string) {
    toaster.notify(message, {
      duration: 7000
    });
  }

  public static isDevelopment() {
    return _.isEqual(process.env.REACT_APP_ENVIRONMENT, 'localhost');
  }

  public static checkOnboardedValue() {
    // Onboarded should always be true when the app is running locally
    const onboarded = Utils.getObjectItem('onboarded');
    return Utils.isDevelopment() ? true : onboarded;
  }

  public static notificationUserMessage = (messageCode: string) => {
    let message;
    switch (messageCode) {
      case 'userInserted':
        message = 'User inserted successfully!';
        break;
      case 'userAlreadyExists':
        message = 'User already exists!';
        break;
      case 'userError':
        message = 'The user could not be inserted!';
        break;
      default:
        break;
    }
    return message;
  };

  public static validateEmail = (email: string) => {
    let errorMessage = '';

    // Check empty email
    if (!email) {
      errorMessage = 'Email cannot be empty';
    }

    // Validate email characters
    if (email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      errorMessage = 'Invalid email';
    }
    return errorMessage;
  };

  public static sortTableData<T>(
    array: T[],
    currentOrder: 'asc' | 'desc',
    orderBy: string
  ): T[] {
    return array.sort((a, b) => {
      const aValue = _.get(a, orderBy, '');
      const bValue = _.get(b, orderBy, '');

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        if (!aValue || !bValue) {
          return currentOrder === 'desc' ? (bValue ? -1 : 1) : aValue ? -1 : 1;
        }
        return currentOrder === 'desc'
          ? bValue.localeCompare(aValue)
          : aValue.localeCompare(bValue);
      }

      if (typeof aValue === 'number' && typeof bValue === 'number') {
        return currentOrder === 'desc' ? bValue - aValue : aValue - bValue;
      }

      return 0;
    });
  }

  public static formatDistanceToNowStrict(
    date: Date,
    options?: Parameters<typeof dateFnsFormatDistanceToNowStrict>[1] & {
      doNotFormatDistanceAfterDays?: number;
    }
  ) {
    const { doNotFormatDistanceAfterDays = 3, ...dateFnsOptions } =
      options || {};
    const daysPassed = differenceInDays(new Date(), date);
    if (daysPassed > doNotFormatDistanceAfterDays) {
      return format(date, 'MMM d, yyyy');
    } else {
      return dateFnsFormatDistanceToNowStrict(date, dateFnsOptions);
    }
  }

  public static durationFormatReadable(duration: moment.Duration) {
    return formatDistance(0, duration.asSeconds() * 1000);
  }

  public static nFormatter(
    num: number,
    digits: number,
    currency: string = 'USD'
  ) {
    const lookup = [
      { value: 1, symbol: '' },
      { value: 1e3, symbol: 'k' },
      { value: 1e6, symbol: 'M' },
      { value: 1e9, symbol: 'B' },
      { value: 1e12, symbol: 'T' },
      { value: 1e15, symbol: 'P' },
      { value: 1e18, symbol: 'E' }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    let item = lookup
      .slice()
      .reverse()
      .find(function (item) {
        return num >= item.value;
      });
    const currencyString = currency ? currency : 'USD';
    return item
      ? (num / item.value).toFixed(digits).replace(rx, '$1') +
          item.symbol +
          ` ${currencyString}`
      : `0 ${currencyString}`;
  }

  public static getAccountHealthRoute(accountName: string) {
    return `${Constants.routes.HEALTH_ACCOUNT}?${
      Constants.ACCOUNT_NAME_SEARCH_PARAM
    }=${encodeURIComponent(accountName)}`;
  }

  public static isDemo(): boolean {
    return _.isEqual(process.env.REACT_APP_MOCKDATA, 'true');
  }

  public static useCustomerQuery(enabled: boolean = true) {
    return useQuery<Customer>(
      [Constants.STORAGE_KEY.CUSTOMER],
      () => IrisPortalService.getCustomer(),
      {
        refetchOnWindowFocus: false,
        onError: (e) => {},
        enabled,
        onSuccess: (caseCategories) => {},
        ...CACHE_TIME.get(CACHE_KEYS.CUSTOMERS)
      }
    );
  }

  public static useUserQuery() {
    return useQuery<User>(
      [Constants.STORAGE_KEY.USER],
      () => IrisPortalService.getUserData(),
      {
        refetchOnWindowFocus: false,
        onError: (e) => {
          // Utils.showNotify(String(e));
        },
        onSuccess: (user) => {},
        ...CACHE_TIME.get(CACHE_KEYS.USER)
      }
    );
  }

  public static isUserAdmin(user: User) {
    return _.includes(user.roles, Role.ADMIN);
  }

  public static amIAdmin(): boolean {
    const { data: user, isLoading: isUserQueryLoading } = Utils.useUserQuery();

    return React.useMemo(() => {
      if (isUserQueryLoading) {
        return false;
      }

      if (_.isNil(user)) {
        return false;
      }

      return Utils.isUserAdmin(user);
    }, [user, isUserQueryLoading]);
  }

  public static useCustomerWithLowestHealthScore(limit?: number) {
    const {
      data: dataCustomerHealth,
      isLoading: isLoadingCustomerHealth,
      isFetching: isFetchingCustomerHealth
    } = useQuery(
      [CACHE_KEYS.CUSTOMER_LOWEST_HEALTH, limit],
      () => IrisPortalService.getCustomersWithLowestHealthScores(limit),
      {
        ...CACHE_TIME.get(CACHE_KEYS.CUSTOMER_LOWEST_HEALTH),
        placeholderData: {
          customerAccounts: []
        } as CustomersWithLowestHealthScores
      }
    );

    return {
      dataCustomerHealth,
      isLoadingCustomerHealth,
      isFetchingCustomerHealth
    };
  }

  public static getImportantSupportMetrics(dateRange: [Date, Date]) {
    const { data: importantSupportMetrics, isLoading } = useQuery(
      [CACHE_KEYS.IMPORTANT_SUPPORT_METRICS_DATA, ...dateRange],
      () => IrisPortalService.getImportantSupportMetrics(...dateRange),
      {
        ...CACHE_TIME.get(CACHE_KEYS.IMPORTANT_SUPPORT_METRICS_DATA),
        refetchOnWindowFocus: false,
        onError: (e) => {
          Utils.showError(String(e));
        },
        onSuccess: (importantSupportMetricsData) => {}
      }
    );

    return {
      importantSupportMetrics,
      isLoading
    };
  }

  public static isLoggedIn(): boolean {
    let tokenExpress = Utils.getObjectItem('token_express');
    return !_.isEmpty(tokenExpress);
  }

  public static calculatePercentChange(
    current: number,
    previous: number
  ): number | null {
    // If both 0 => UI show 0%
    if (previous === 0 && current === 0) {
      return 0;
      // If anyone of them is 0 => UI show N/A
    } else if (previous === 0 || current === 0) {
      return null;
    } else {
      return ((current - previous) / previous) * 100;
    }
  }

  public static openInNewTab(url: string) {
    window.open(url, '_blank');
  }

  public static getStarterCustomerId(): string {
    return Utils.getObjectItem(Constants.STARTER_CUSTOMER_ID);
  }

  public static isOriginalCustomer(): boolean {
    return (
      _.isEmpty(Utils.getStarterCustomerId()) ||
      _.isEqual(Utils.getStarterCustomerId(), Utils.getUserItem().customerId)
    );
  }

  public static getCustomerId(): string {
    const user = Utils.getObjectItem('user');
    return user?.customerId;
  }

  public static getFrontendAPIServerUrl(): string {
    if (!process.env.REACT_APP_APISERVER_URL) {
      throw new Error('REACT_APP_APISERVER_URL not set');
    }
    return process.env.REACT_APP_APISERVER_URL;
  }

  /**
   * @description Ensure that the value is an Error instance. If it's not, it will be stringified and thrown as an Error.
   * This is useful when you are almost certain that the value is an Error, but you want to be sure.
   * @param value
   */
  public static ensureError(value: unknown): Error {
    if (value instanceof Error) return value;

    let stringified = '[Unable to stringify the thrown value]';
    try {
      stringified = JSON.stringify(value);
    } catch {
      Sentry.captureException(value);
    }

    return new Error(
      `This value was thrown as is, not through an Error: ${stringified}`
    );
  }
}

export default Utils;
