import React, { ReactElement, useCallback, useEffect, useState } from 'react';

import { ActionRowWrapper } from './ActionRow';
import { Stack } from '../../../../components/common';
import { NotImplementedRowManager } from '../RowManagers/NotImplementedRowManager';
import { RulesDropdownButton } from '../RulesDropdownButton';

import { Action, ConditionContainer, Option } from '../../types';
import './styles.scss';
import { Switch } from '../../../../components/switch/Switch';
import { RulesAddButton } from '../RulesAddButton';
import { operators } from '../../constants';

export const RulesManager: React.FC<{
  beforeButtonTitle: string;
  afterButtonTitle: string;
  values: any;
  options: Option[]; // constants options (found in ../../constants.ts)
  handleActionsChange: (
    operator: string,
    actionRowWrappers: ConditionContainer[]
  ) => void; // parent handle function
  buttonIcon: ReactElement;
  buttonColor: string;
  type: string;
  conditionToolClicked: string;
  firstOperand: string;
  onTriggersEmpty?: () => void;
  triggersEmpty?: boolean;
  isEditable: boolean;
}> = ({
  beforeButtonTitle,
  afterButtonTitle,
  values,
  options,
  handleActionsChange,
  buttonIcon,
  buttonColor,
  type,
  conditionToolClicked,
  firstOperand,
  isEditable,
  onTriggersEmpty
}) => {
  const [actionRowWrappers, setActionRowWrappers] = useState<
    ConditionContainer[]
  >([]);

  useEffect(() => {
    if (values?.length === 0) return;

    let newActionRowWrappers: ConditionContainer[] = [];
    newActionRowWrappers = values?.map((value: any) => {
      if (value.operands) {
        const rowWrapper = value.operands.map((triggerValue: any) => {
          const keyTrigger = triggerValue.fact
            ? triggerValue.fact
            : triggerValue.type;

          const valueTrigger = triggerValue.value
            ? triggerValue.value
            : triggerValue.data?.value;

          const operatorTrigger = triggerValue.operator;

          const rowManagerFactory =
            options[options.findIndex((value) => value.keyName === keyTrigger)];

          const rowManager =
            rowManagerFactory !== undefined &&
            rowManagerFactory.rowFactoryFn !== undefined
              ? rowManagerFactory.rowFactoryFn(
                  options,
                  valueTrigger,
                  operatorTrigger
                )
              : new NotImplementedRowManager(options, undefined, undefined, '');

          return new ActionRowWrapper(rowManager, options);
        });

        const groupOperator = value.operator;
        const innerRowWrapper: ConditionContainer = {
          operator: groupOperator,
          actions: rowWrapper
        };
        return innerRowWrapper;
      } else {
        let keyTrigger = value.fact ? value.fact : value.type;
        const valueTrigger = value.value ? value.value : value.data?.value;

        const operatorTrigger = value.operator;

        const rowManagerFactory =
          options[options.findIndex((value) => value.keyName === keyTrigger)];

        const rowManager =
          rowManagerFactory !== undefined &&
          rowManagerFactory.rowFactoryFn !== undefined
            ? rowManagerFactory.rowFactoryFn(
                options,
                valueTrigger,
                operatorTrigger
              )
            : new NotImplementedRowManager(options, undefined, undefined, '');

        const rowWrapper = new ActionRowWrapper(rowManager, options);
        return { operator: 'and', actions: [rowWrapper] };
      }
    });

    setActionRowWrappers(newActionRowWrappers);
  }, [values, options]);

  useEffect(() => {
    if (conditionToolClicked) handleRulesDropdownSelect(conditionToolClicked);
    // eslint-disable-next-line
  }, [conditionToolClicked]);

  /**
   * Helps to handle when the "Add" button is clicked inside an existing condition box
   */
  const handleInnerConditionAdd = useCallback(
    (row: ConditionContainer, index: number) => {
      // const newActionRowWrappers = [...actionRowWrappers];

      actionRowWrappers[index] = row;
      // newActionRowWrappers[index] = row;
      handleActionsChange(firstOperand, actionRowWrappers);
      setActionRowWrappers(actionRowWrappers);
    },
    [actionRowWrappers, setActionRowWrappers, handleActionsChange, firstOperand]
  );

  /**
   * Helps to handle when the "Add" button is clicked
   * @param {string} actionName Name of an action that was selected when "Add" button was clicked
   */
  const handleRulesDropdownSelect = useCallback(
    (actionName: string) => {
      const rowManagerFactory =
        options[options.findIndex((value) => value.name === actionName)];
      if (rowManagerFactory === undefined) {
        return;
      }
      const rowManager =
        rowManagerFactory.rowFactoryFn === undefined
          ? new NotImplementedRowManager(options, undefined, undefined, '')
          : rowManagerFactory.rowFactoryFn(options);
      const newRow = new ActionRowWrapper(rowManager, options);
      const conditionContainer: ConditionContainer = {
        operator: 'and',
        actions: [newRow]
      };
      actionRowWrappers.push(conditionContainer);
      // const newActionRowWrappers = [...actionRowWrappers, [newRow]];
      setActionRowWrappers(actionRowWrappers);
      handleActionsChange(firstOperand, actionRowWrappers);
    },
    [actionRowWrappers, handleActionsChange, options, firstOperand]
  );

  /**
   * Helps to handle when an Action is updated
   * @param action Action that was changed
   */
  const handleRowComponent = useCallback(
    (action: Action) => {
      const newActionRowWrappers = [...actionRowWrappers];
      let wrapperIndex = 0;
      let actionIndex = 0;
      newActionRowWrappers.map((wrapper, index) => {
        const tempIndex = wrapper.actions.findIndex(
          (value) => value.rowManager.action.id === action.id
        );
        if (tempIndex !== -1) {
          actionIndex = tempIndex;
          wrapperIndex = index;
        }
        return wrapper;
      });

      newActionRowWrappers[wrapperIndex].actions[
        actionIndex
      ].rowManager.action = action;
      setActionRowWrappers(newActionRowWrappers);
      handleActionsChange(firstOperand, newActionRowWrappers);
    },
    [actionRowWrappers, handleActionsChange, firstOperand]
  );

  const onChangeCondition = (id: number) => {
    firstOperand = operators[id];
    handleActionsChange(firstOperand, actionRowWrappers);
  };

  const onChangeInnerCondition = (index: number, parentIndex: number) => {
    actionRowWrappers[parentIndex].operator = operators[index];
    handleActionsChange(firstOperand, actionRowWrappers);
  };

  /**
   * Helps to handle when an Action is deleted (trash bin clicked)
   * @param deletedAction Action to be deleted
   */
  const handleRowDelete = useCallback(
    (deletedAction: Action) => {
      const newActionRowWrappers = [...actionRowWrappers];
      let wrapperIndex = 0;
      let actionIndex = 0;
      newActionRowWrappers.map((wrapper, index) => {
        const tempIndex = wrapper.actions.findIndex(
          (value) => value.rowManager.action.id === deletedAction.id
        );
        if (tempIndex !== -1) {
          actionIndex = tempIndex;
          wrapperIndex = index;
        }
        return wrapper;
      });
      newActionRowWrappers[wrapperIndex].actions.splice(actionIndex, 1);
      if (newActionRowWrappers[wrapperIndex].actions.length === 0) {
        newActionRowWrappers.splice(wrapperIndex, 1);
      }
      setActionRowWrappers(newActionRowWrappers);
      if (newActionRowWrappers.length === 0) {
        if (onTriggersEmpty) onTriggersEmpty();
      }
      handleActionsChange(firstOperand, newActionRowWrappers);
    },
    [actionRowWrappers, handleActionsChange, firstOperand, onTriggersEmpty]
  );

  const handleGroupRowDelete = useCallback(
    (deletedAction: Action) => {
      const newActionRowWrappers = [...actionRowWrappers];
      let wrapperIndex = 0;
      newActionRowWrappers.map((wrapper, index) => {
        const tempIndex = wrapper.actions.findIndex(
          (value) => value.rowManager.action.id === deletedAction.id
        );
        if (tempIndex !== -1) {
          wrapperIndex = index;
        }
        return wrapper;
      });
      newActionRowWrappers.splice(wrapperIndex, 1);
      setActionRowWrappers(newActionRowWrappers);
      if (newActionRowWrappers.length === 0) {
        if (onTriggersEmpty) onTriggersEmpty();
      }
      handleActionsChange(firstOperand, newActionRowWrappers);
    },
    [actionRowWrappers, handleActionsChange, firstOperand, onTriggersEmpty]
  );

  return (
    <>
      <Stack
        align="center"
        justify="flex-start"
        style={{
          pointerEvents: actionRowWrappers.length === 0 ? 'auto' : 'none'
          // marginBottom: '16px'
        }}
      >
        <RulesDropdownButton
          onChange={handleRulesDropdownSelect}
          options={options}
          startIcon={buttonIcon}
          initialState={actionRowWrappers.length === 0}
          type={type}
        >
          <span className="button-text" style={{ color: buttonColor }}>
            {actionRowWrappers.length === 0
              ? beforeButtonTitle
              : afterButtonTitle}
          </span>
        </RulesDropdownButton>
      </Stack>
      <div
        style={{
          background: type === 'trigger' ? '#F8F8FF' : '#FFF6F9',
          marginTop: '-50px'
        }}
      >
        {actionRowWrappers.length > 0 &&
          actionRowWrappers[0].actions !== undefined &&
          actionRowWrappers[0].actions.length > 0 && (
            <div
              style={{
                borderLeft:
                  actionRowWrappers[0]?.actions[0]?.rowManager?.type ===
                  'action'
                    ? '2px solid #fcddec'
                    : '2px solid #a5a6f6',
                // height: '36px'

                paddingTop: '40px',
                marginLeft: '12px',
                paddingBottom: '10px'
              }}
            >
              {actionRowWrappers.map((row, parentIndex) => {
                return (
                  <div className={row.actions.length > 1 ? 'group' : ''}>
                    {row.actions.length > 1 && (
                      <div
                        style={{ marginLeft: '-10px', marginBottom: '-20px' }}
                      >
                        <Switch
                          buttons={['And', 'Or']}
                          onChange={(index) =>
                            onChangeInnerCondition(index, parentIndex)
                          }
                          defaultSelection={row.operator === 'and' ? 0 : 1}
                        />
                      </div>
                    )}
                    {row.actions.map((item, index) => {
                      return item.createRowComponent(
                        handleRowComponent,
                        handleRowDelete,
                        handleInnerConditionAdd,
                        handleGroupRowDelete,
                        row,
                        parentIndex,
                        index
                      );
                    })}
                  </div>
                );
              })}
            </div>
          )}
        {actionRowWrappers.length > 0 &&
          actionRowWrappers[0]?.actions[0].rowManager?.type !== 'action' && (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div className="and-or">
                <Switch
                  buttons={['And', 'Or']}
                  onChange={onChangeCondition}
                  defaultSelection={firstOperand === 'and' ? 0 : 1}
                />
              </div>
              <RulesAddButton
                onChange={handleRulesDropdownSelect}
                options={options}
              >
                <span
                  style={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '10px'
                  }}
                >
                  Add condition group
                </span>
              </RulesAddButton>
            </div>
          )}
        {actionRowWrappers.length > 0 &&
          actionRowWrappers[0]?.actions[0].rowManager?.type === 'action' && (
            <Stack
              align="center"
              justify="flex-start"
              style={{ marginLeft: '-7px' }}
            >
              <RulesAddButton
                onChange={handleRulesDropdownSelect}
                options={options}
              >
                <span
                  style={{
                    color: 'white',
                    textTransform: 'none',
                    fontSize: '10px'
                  }}
                >
                  Add action
                </span>
              </RulesAddButton>
            </Stack>
          )}
      </div>
    </>
  );
};
