import Utils from '../../utils/utils';
import _ from 'lodash';
import moment from 'moment';

export const isMocKData =
  process.env.REACT_APP_MOCKDATA === 'true' ? true : false;

export const handleError = (codeStatus: number) => {
  let errorMessage;
  switch (codeStatus) {
    case 401:
    case 403:
      logout();
      break;
    case 404:
      errorMessage = 'Not found!';
      Utils.showError(errorMessage);
      break;
    default:
      break;
  }
};

export const logout = _.debounce(
  (showMessage = true) => {
    if (showMessage) {
      let errorMessage = 'Not authorized!';
      Utils.showNotify(errorMessage);
    }

    window.location.href = '/';
    Utils.clearStorage();
    // Saving the current path in the local storage
    // to maintain the user context after login
    Utils.saveObjectItem('meta', {
      next: window.location.pathname + window.location.search
    });
  },
  moment.duration(1, 'second').asMilliseconds(),
  {
    maxWait: 0
  }
);
