import React, { FC } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { TextField, withStyles } from '@material-ui/core';
import { Stack } from '../../../components/common';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import { debounce } from 'debounce';

const NoPaddingAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 3
    }
  },
  input: {}
})(Autocomplete);

type SearchClasses = {
  root?: string;
};

type Search = {
  searchValue: string;
  placeholder?: string;
  dataList?: string[];
  dataListSearch?: any[];
  isCustom?: boolean;
  deactivateDropDown?: boolean;
  deactivateTitle?: boolean;
  withoutBorder?: boolean;
  withoutBorderInput?: boolean;
  showListSearch?: boolean;
  onlyTextItems?: boolean;
  redirectWithId?: boolean;
  showDropdown?: boolean;
  onInputChange: (input: string) => void;
  getOptionSelected?: (optionSelected: string) => void;
  getClickCardMetrics?: (optionSelected: string) => void;
  classes?: SearchClasses;
};

const SearchWidget: FC<Search> = (props) => {
  const {
    searchValue,
    placeholder,
    dataList = [],
    isCustom = true,
    withoutBorderInput = false,
    deactivateDropDown = false,
    onInputChange,
    classes: { root: rootClass = '' } = {},
    showDropdown
  } = props;

  const onChange = (value: string) => {
    onInputChange(value);
  };
  const preFetchFilterData = debounce((filterName: string | null) => {
    console.log(searchValue, filterName);
    if (filterName !== searchValue) {
      onInputChange(filterName ? filterName : '');
    }
  }, 200);
  return (
    <div style={{ width: 'inherit', height: 'inherit' }} className={rootClass}>
      <Stack
        direction="vertical"
        gap={10}
        align="flex-start"
        style={{ width: 'inherit', height: 'inherit' }}
      >
        <NoPaddingAutocomplete
          open={showDropdown !== undefined ? showDropdown : undefined}
          size="small"
          options={dataList}
          openOnFocus={false}
          style={{
            backgroundColor: isCustom ? 'white' : '',
            width: '100%',
            height: 'inherit'
          }}
          freeSolo={deactivateDropDown}
          onChange={(event: object, value: any) => {
            if (showDropdown === undefined) {
              onChange(value);
            }
          }}
          onInputChange={(event, newInputValue) => {
            if (showDropdown === false) {
              preFetchFilterData(newInputValue);
            }
            // preFetchFilterData(newInputValue);
          }}
          value={searchValue}
          renderInput={(params) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                width: 'inherit',
                height: 'inherit'
              }}
            >
              <TextField
                style={{ height: 'inherit', paddingTop: 0 }}
                {...params}
                placeholder={placeholder ? placeholder : 'Search'}
                variant={withoutBorderInput ? 'standard' : 'outlined'}
                InputProps={{
                  ...params.InputProps,
                  // classes:
                  classes: undefined,
                  startAdornment: isCustom && (
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  )
                }}
              />
            </div>
          )}
        />
      </Stack>
    </div>
  );
};

export default SearchWidget;
