import React, { useEffect } from 'react';
import './App.css';
import Login from './routes/login/Login';
import Dashboard from './routes/dashboard/Dashboard';
import Platforms from './routes/platforms/Platforms';
import { HealthAccount } from './routes/customerHealth';
import { AgentDetails, AgentOverview } from './routes/agent';
import ManageUsers from './routes/manage-users/ManageUsers';
import ManageAccounts from './routes/manage-accounts/ManageAccounts';
import ConnectOrganization from './routes/connect-organization/ConnectOrganization';
import { RuleDetails, Rules } from './routes/rules';
import IncidentsDetails from './routes/incidents/incidents-details/IncidentsDetails';
import { initAmplitude } from './utils/amplitude';
import Utils from './utils/utils';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { DetailedView, Overview } from './routes/categories';
import { QueryClientProvider } from 'react-query';
import { AccountDetails } from './routes/customerHealth/components/AccountDetails';
import { queryClient } from './utils/queryCache';
import { GetSlackToken } from './routes/GetSlackToken';
import Constants from './utils/constants';
import FreeTrialExpired from './routes/free-trial-expired/FreeTrialExpired';
import { PortalLayout } from './components/PortalLayout';
import IrisGPT from './routes/irisgpt/IrisGPT';
import ProtectedRoute from './ProtectedRoute';
import { NeedsAttention } from './routes/needs-attention/NeedsAttention';
import { HealthOverview } from './routes/insights/HealthOverview';
import { Escalations } from './routes/insights/Escalations';
import { AutomationCategories } from './routes/automation/AutomationCategories';
import { ScrollToTop } from './components/ScrollToTop';
import ManageChatbot from './routes/manage-chatbot/ManageChatbot';
import { AutomationImpact } from './routes/automation/AutomationImpact';
import ChatbotActivityDashboard from './routes/chatbot-activity/ChatbotActivity';
import ArticleSearch from './routes/article-search/ArticleSearch';
import { Routing } from './routes/automation/Routing';
import { OktaAuthRedirect } from './routes/OktaAuthRedirect';

// Define any global variables here. Any functions or variables that you are using on the window object can be defined
// here to access the across the code base without any type errors.
declare global {
  interface Window {
    initIrisAgentChatbot: (externalIdentifier: string, style?: any) => void;
  }
}

function AppPlaceholder() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        {/* {user.sourceName && <TabNavigation />} */}
        <PortalLayout>
          <div className="App">
            <Switch>
              <Route exact path={Constants.routes.LOGIN} component={Login} />
              {/*<ProtectedRoute*/}
              {/*  exact*/}
              {/*  path={Constants.routes.HOME}*/}
              {/*  component={Home}*/}
              {/*/>*/}
              <Route
                exact
                path={Constants.routes.DASHBOARD}
                component={Dashboard}
              />
              <Route
                exact
                path={Constants.routes.PLATFORMS}
                component={Platforms}
              />
              <Route
                exact
                path={Constants.routes.FREE_TRIAL_EXPIRED}
                component={FreeTrialExpired}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.HEALTH_OVERVIEW}
                component={HealthOverview}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.HEALTH_ACCOUNT}
                component={HealthAccount}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.ACCOUNT_DETAILS}
                component={AccountDetails}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.CATEGORIES_OVERVIEW}
                component={Overview}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.AUTOMATION_CATEGORIES}
                component={AutomationCategories}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.DETAILED_VIEW}
                component={DetailedView}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.AGENT_OVERVIEW}
                component={AgentOverview}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.AGENT_DETAILS}
                component={AgentDetails}
              />
              {/*<ProtectedRoute*/}
              {/*  exact*/}
              {/*  path={Constants.routes.RECENT_INCIDENTS}*/}
              {/*  component={RecentIncidents}*/}
              {/*/>*/}
              <ProtectedRoute
                exact
                path={Constants.routes.IRIS_GPT}
                component={IrisGPT}
              />
              <ProtectedRoute exact path={'/playground'} component={IrisGPT} />
              <ProtectedRoute
                exact
                path={Constants.routes.MANAGE_USERS}
                component={ManageUsers}
                backfillProtected={false}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.MANAGE_ACCOUNTS}
                component={ManageAccounts}
                backfillProtected={false}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.MANAGE_CHATBOT}
                component={ManageChatbot}
                backfillProtected={false}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.CHATBOT_ACTIVITY}
                component={ChatbotActivityDashboard}
                backfillProtected={false}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.ARTICLE_SEARCH}
                component={ArticleSearch}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.AUTOMATION_ROUTING}
                component={Routing}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.GET_SLACK_TOKEN}
                component={GetSlackToken}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.MANAGE_ORGANIZATIONS}
                component={ConnectOrganization}
                backfillProtected={false}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.TRIGGERS}
                component={Rules}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.TRIGGERS_DETAILS}
                component={RuleDetails}
              />
              <ProtectedRoute
                exact
                path="/incidents-details/:incidentSource/:incidentId"
                component={IncidentsDetails}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.NEEDS_ATTENTION}
                component={NeedsAttention}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.ESCALATIONS}
                component={Escalations}
              />
              <ProtectedRoute
                exact
                path={Constants.routes.AUTOMATION_IMPACT}
                component={AutomationImpact}
              />
              <Route
                exact
                path={Constants.routes.OKTA_AUTH_REDIRECT}
                component={OktaAuthRedirect}
              />
              <Redirect to={Constants.routes.LOGIN} />
            </Switch>
          </div>
        </PortalLayout>
      </ScrollToTop>
    </BrowserRouter>
  );
}

function App() {
  initAmplitude();
  let user = Utils.getObjectItem('user');
  // throw new Error('My first Sentry error!');
  useEffect(() => {
    const chatbotDiv = document.getElementById('iris-agent-chatbot-div');
    if (window.location.pathname !== '/irisgpt' && chatbotDiv) {
      chatbotDiv.style.display = 'none';
    }
    const email = user?.email || user?.data?.email;
    // Expecting error here, since TS cannot recognize
    // dynamically added keys to the window object
    // @ts-expect-error
    if (window.hj && email) {
      // @ts-expect-error
      window.hj('identify', email, {
        email,
        sourceName: user.sourceName,
        organizationId: user.sourceOrganizationId,
        customerId: user.customerId
      });
    }
  }, [user]);

  return (
    <QueryClientProvider client={queryClient}>
      <AppPlaceholder />
    </QueryClientProvider>
  );
}
export default App;
