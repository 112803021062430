import React, { FC, useEffect, useMemo, useState } from 'react';
import { CategoryFilter } from '../types';
import { AdvancedFilter, SelectedItem } from './filterComponent/AdvancedFilter';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { endOfDay, startOfDay } from 'date-fns';
import { MenuListCompositionValue } from '../../customerHealth/components/MenuListComposition';
import _ from 'lodash';
import { CategoriesTable, TableHeaderCells } from './CategoriesTable';
import Utils from '../../../utils/utils';
import { Heading, Stack, Subtitle, Text } from '../../../components/common';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import SearchWidget from './SearchWidget';
import Grid from '@material-ui/core/Grid';
import { SortSelection } from './sortComponent/SortSelection';
import { CSVLink } from 'react-csv';
import { Button } from '../../../components/home/Button';
import { ReactComponent as BlueTickSvg } from '../../../assets/BlueTick.svg';
import { useDateRangeQuery } from '../utils';
import Constants, {
  DEFAULT_END_DATE,
  START_DATE_LAST_30_DAYS
} from '../../../utils/constants';
import { useQuery } from 'react-query';
import { CACHE_KEYS, CACHE_TIME } from '../../../utils/cacheUtils';
import IrisPortalService from '../../../services/IrisPortalService';
import { useResetPage } from '../../../hooks/useResetPage';
import { Loader } from '../../../components/common/blocks/Loader';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import { Drawer } from '@material-ui/core';
import { CustomerProvidedTagPanel } from './customer-provided-tag/CustomerProvidedTagPanel';

export interface CategoriesOverviewProps extends React.ComponentProps<any> {
  onCaseCategoriesInfoChange?: (
    isLoading: boolean,
    data: any[] | undefined
  ) => void;
}

export const CategoriesOverview: FC<CategoriesOverviewProps> = (props) => {
  const { onCaseCategoriesInfoChange } = props;

  const { data: content, isLoading: areFiltersLoading } = useQuery(
    [CACHE_KEYS.CATEGORY_FILTERS],
    () => IrisPortalService.getCaseCategoryFilters(),
    {
      refetchOnWindowFocus: false,
      onError: (e) => {
        Utils.showError(String(e));
      },
      onSuccess: () => {},
      ...CACHE_TIME.get(CACHE_KEYS.CATEGORY_FILTERS)
    }
  );

  const [attributeFilter, setAttributeFilter] = useState<any>({});

  const [dateRange, setDateRange] = useState<[Date, Date]>(() => {
    return [START_DATE_LAST_30_DAYS, DEFAULT_END_DATE];
  });

  const {
    data: caseCategoriesInfo,
    isLoading: isDataLoading
  } = useDateRangeQuery(dateRange, attributeFilter);

  const [categoryFilters, setCategoryFilters] = useState<CategoryFilter[]>([]);

  useEffect(() => {
    const newCategoryFilters = content?.filters.map(
      (filter: CategoryFilter) => {
        const filterValues = filter.values.map((val) => {
          return { value: val.toString(), isChecked: false };
        });

        return {
          ...filter,
          ...{
            values: filterValues
          }
        };
      }
    );

    setCategoryFilters(newCategoryFilters);
    // console.log(`Content.filters: ${JSON.stringify(content)}`)
  }, [content]);

  useEffect(() => {
    Utils.removeObjectItem(Constants.FILTERS_CATEGORIES);
  }, []);

  const [isLoading, setIsLoading] = React.useState<boolean>(
    isDataLoading || areFiltersLoading
  );
  React.useEffect(() => {
    setIsLoading(isDataLoading || areFiltersLoading);
  }, [isDataLoading, areFiltersLoading, setIsLoading]);

  useResetPage();

  // Auto selects the first category when the page loads
  // This will not auto select the first category when the date range is changed
  // This will also preserve the hash already present in the URL
  // to bring the user back to the same category when she reloads the page
  useEffect(() => {
    Utils.saveObjectItem(Constants.STORAGE_KEY.DATE_RANGE_CATEGORIES_OVERVIEW, {
      current: dateRange
    });
  }, [dateRange]);

  let [caseCategories, setCaseCategories] = useState<any[]>([]);
  let categoryNames = caseCategories.map((item) => {
    return item.name;
  });

  const [searchValue, setSearchValue] = useState<string>('');
  useEffect(() => {
    if (isDataLoading) {
      return;
    }

    setSearchValue(caseCategoriesInfo?.filter || '');
  }, [isDataLoading, caseCategoriesInfo]);

  // Filter Data by search string
  useEffect(() => {
    if (isDataLoading) {
      return;
    }

    const caseCategoriesData = caseCategoriesInfo?.caseCategoriesData || [];

    if (searchValue) {
      const wordsInput = searchValue.split(/_|-| /).filter((val: any) => val);

      setCaseCategories(
        caseCategoriesData.filter(
          (category: any) =>
            wordsInput.filter(
              (word: any) =>
                category.name
                  .split('_')
                  .join('')
                  .toLowerCase()
                  ?.indexOf(word.toLowerCase().trim()) > -1
            ).length === wordsInput.length
        )
      );
    } else {
      setCaseCategories(caseCategoriesData);
    }
  }, [isDataLoading, caseCategoriesInfo, searchValue]);

  // Watch out for changes in caseCategories
  React.useEffect(() => {
    if (!_.isNil(onCaseCategoriesInfoChange)) {
      onCaseCategoriesInfoChange(isLoading, caseCategories);
    }
  }, [isLoading, caseCategories, onCaseCategoriesInfoChange]);

  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Category Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };
  const [sortField, setSortField] = useState<MenuListCompositionValue>();
  const [sortFlag, setSortFlag] = useState<boolean>(false);

  const exportData = useMemo(() => {
    const headers = _.map(TableHeaderCells, 'label');
    headers.push('SECONDARY CATEGORY DISTRIBUTION');

    const data = [headers];

    _.each(caseCategories, (caseCategoryData) => {
      const row: any[] = [];

      const avgSentimentScore =
        caseCategoryData.averageSentimentScore === null
          ? '-'
          : Math.round(caseCategoryData?.averageSentimentScore * 100);

      row.push(caseCategoryData.name);
      row.push(caseCategoryData.count);
      row.push(
        caseCategoryData.mttr?.mttr
          ? caseCategoryData.mttr?.mttr.toFixed(0)
          : '-'
      );
      row.push(`${avgSentimentScore}%`);
      row.push(caseCategoryData.averageNumberOfComments || 0);
      row.push(
        caseCategoryData.csat?.score ? caseCategoryData.csat?.score + '%' : '-'
      );
      row.push(Utils.getCurrencyValue(caseCategoryData.revenueImpacted));
      row.push(
        _.chain(caseCategoryData.secondaryCategoryDistribution)
          .mapValues((val, key) => {
            return `${key}: ${val}`;
          })
          .values()
          .join(',')
          .value()
      );

      data.push(row);
    });

    return data;
  }, [caseCategories]);

  const [allMenuListValues] = useState<MenuListCompositionValue[]>([
    {
      label: 'Category Name',
      formattedLabel: 'Category Name',
      sortDownIcon: '/images/sortDown.svg',
      sortUpIcon: '/images/sortUp.svg',
      icon: '/images/sortDown.svg',
      field: 'name'
    },
    {
      label: 'Number of Cases',
      formattedLabel: 'Number of Cases',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'casesCount'
    },
    {
      label: 'Mean Time to resolve',
      formattedLabel: 'Mean Time to resolve',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'mttr'
    },
    {
      label: 'Sentiment Score',
      formattedLabel: 'Sentiment Score',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'averageSentimentScorePercent'
    },
    {
      label: 'Comments',
      formattedLabel: 'Comments',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'averageNumberOfComments'
    },
    {
      label: 'CSAT Score',
      formattedLabel: 'CSAT Score',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'csat'
    },
    {
      label: 'Revenue Impacted',
      formattedLabel: 'Revenue Impacted',
      sortDownIcon: '/images/sortNumberDown.svg',
      sortUpIcon: '/images/sortNumberUp.svg',
      icon: '/images/sortNumberDown.svg',
      field: 'revenueImpacted'
    }
  ]);
  const onSortChange = (field: MenuListCompositionValue) => {
    //console.log('in chang', field);
    if (field.icon === field.sortDownIcon) {
      field.icon = field.sortUpIcon;
    } else {
      field.icon = field.sortDownIcon;
    }
    setSortField(field);
    setSortFlag(!sortFlag);
    //console.log('after chang', field);
  };

  const numberTicketsRespondedArr = _.map(caseCategories, function (value) {
    return value?.automationTriggerDetails?.numberOfTicketsAutoResponded || 0;
  });
  const numberTicketsResponded = _.sum(numberTicketsRespondedArr);
  const totalTicketsPerCategory = _.map(caseCategories, function (value) {
    return value.count || 0;
  });
  const totalTickets = _.sum(totalTicketsPerCategory);

  const [buttonsFlexDirection, setButtonsFlexDirection] = useState<
    'row' | 'column'
  >('row');

  const handleResize = () => {
    if (window.innerWidth < 992) {
      // Adjust the breakpoint as needed
      setButtonsFlexDirection('column');
    } else {
      setButtonsFlexDirection('row');
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    handleResize(); // Initial call to set the initial flex-direction based on window size

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [categoryIntentDrawerOpen, setCategoryIntentDrawerOpen] = useState(
    false
  );

  if (isLoading) {
    return <Loader center />;
  }

  return (
    <TablePaper style={{ marginTop: '2%' }}>
      <Stack direction="vertical" gap={20}>
        {/* Header */}
        <Stack
          gap={20}
          justify="space-between"
          align="center"
          style={{ marginTop: 16 }}
        >
          <Stack gap={5} align="baseline" style={{ alignItems: 'center' }}>
            <Heading>All Categories</Heading>
            <Text variant="p1" weight="semi-bold">
              {caseCategories.length} ITEMS
            </Text>

            {numberTicketsResponded > 0 && (
              <React.Fragment>
                <BlueTickSvg style={{ marginLeft: 40 }} />
                <Text>
                  {Math.ceil((100.0 * numberTicketsResponded) / totalTickets)}%
                  Tickets Automated
                </Text>
              </React.Fragment>
            )}
          </Stack>

          <DateRangePicker value={dateRange} onChange={handleDateRangeChange} />
        </Stack>
        <div
          style={{
            display: 'flex',
            flexDirection: buttonsFlexDirection
          }}
        >
          <div
            style={{
              width: '300px',
              height: '40px',
              marginRight: '16px'
            }}
          >
            <SearchWidget
              showDropdown={false}
              placeholder="Search in categories"
              isCustom={true}
              searchValue={searchValue}
              dataList={categoryNames}
              onInputChange={(input: string) => {
                setSearchValue(input);
                // console.log('input', input);
              }}
            />
          </div>

          <Grid
            container
            spacing={1}
            style={{
              marginLeft: 'auto'
            }}
          >
            <Grid
              item
              lg={2}
              md={2}
              sm={4}
              xs={12}
              style={{
                marginLeft: 'auto'
              }}
            >
              <SortSelection
                allMenuListValues={allMenuListValues}
                onChange={onSortChange}
              ></SortSelection>
            </Grid>

            {!_.isEmpty(categoryFilters) && (
              <Grid item lg={2} md={2} sm={4} xs={12}>
                <AdvancedFilter
                  onFiltersChange={onFiltersChange}
                  filters={categoryFilters}
                ></AdvancedFilter>
              </Grid>
            )}

            <Grid item lg={2} md={2} sm={4} xs={12}>
              <Button
                variant="outlined"
                onClick={() => setCategoryIntentDrawerOpen(true)}
              >
                + Add ML category
              </Button>
              <Drawer
                anchor="right"
                open={categoryIntentDrawerOpen}
                onClose={() => setCategoryIntentDrawerOpen(false)}
              >
                <CustomerProvidedTagPanel
                  closeDrawer={() => setCategoryIntentDrawerOpen(false)}
                />
              </Drawer>
            </Grid>

            <Grid item lg={2} md={2} sm={4} xs={12}>
              <CSVLink
                data={exportData}
                filename={'irisagent_categories.csv'}
                style={{
                  marginLeft: 'auto'
                }}
              >
                <Button variant="outlined">Export to CSV</Button>
              </CSVLink>
            </Grid>
          </Grid>
        </div>
        <Subtitle>
          Categories/Tags are key topics assigned to cases automatically.{' '}
          <a
            href="https://calendly.com/palak-iris/irisagent-setup"
            target="_blank"
            rel="noopener noreferrer"
          >
            Contact us
          </a>{' '}
          to enable automatic case tagging and customization.
        </Subtitle>
        {/* Data */}
        <CategoriesTable
          dateRange={dateRange}
          caseCategories={caseCategories}
          sortField={sortField}
          sortFlag={sortFlag}
          caseCountWithoutFilter={_.size(caseCategories)}
          searchValue={searchValue}
        />
      </Stack>
    </TablePaper>
  );

  function onFiltersChange(items: SelectedItem[]) {
    // Note: Setting Raw Value doesn't seem to trigger re-render. So deep
    // cloning the object & setting it again which is triggering the re-render
    const newAttributeFilter: any = {};
    items.map((item) => {
      newAttributeFilter[item.key] = item.values;
      return item;
    });
    setAttributeFilter(newAttributeFilter);

    Utils.saveObjectItem(Constants.FILTERS_CATEGORIES, {
      current: newAttributeFilter
    });
  }
};
