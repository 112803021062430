import { CategoriesRowManager } from './components/RowManagers/CategoriesRowManager';
import { TagsRowManager } from './components/RowManagers/TagsRowManager';
import { SubjectRowManager } from './components/RowManagers/SubjectRowManager';
import { TicketContentRowManager } from './components/RowManagers/TicketContentRowManager';
import { AccountNameRowManager } from './components/RowManagers/AccountNameRowManager';
import { Action, Option } from './types';
import { AssignAgentRowManager } from './components/RowManagers/AssignAgentRowManager';
import { ChangeCaseStatusRowManager } from './components/RowManagers/ChangeCaseStatusRowManager';
import { AutoRespondRowManager } from './components/RowManagers/AutoRespondRowManager';
import { CustomFieldRowManager } from './components/RowManagers/CustomFieldRowManager';
import { AssignTeamRowManager } from './components/RowManagers/AssignTeamRowManager';
import { WriteCaseTagsRowManager } from './components/RowManagers/WriteCaseTagsRowManager';
import { WriteCaseCustomFieldRowManager } from './components/RowManagers/WriteCaseCustomFieldRowManager';
import { PriorityRowManager } from './components/RowManagers/PrioritiesRowManager';

export const CONDITIONS_LABEL: any = {
  categories: 'Categories',
  tags: 'Tags',
  ticket_priority: 'Ticket Priority',
  custom_field: 'Custom Field',
  subject: 'Subject',
  ticket_content: 'Ticket Content',
  account_name: 'Account Name'
};

export const operators: string[] = ['and', 'or'];

export const CONDITIONS_RULE: Option[] = [
  {
    name: 'categories',
    label: CONDITIONS_LABEL['categories'],
    keyName: 'category',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CategoriesRowManager(options, action, operatorValue)
  },
  {
    name: 'tags',
    label: CONDITIONS_LABEL['tags'],
    keyName: 'tags',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new TagsRowManager(options, action, operatorValue)
  },
  {
    name: 'custom_field',
    label: CONDITIONS_LABEL['custom_field'],
    keyName: 'custom_field',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new CustomFieldRowManager(options, action, operatorValue)
  },
  {
    name: 'subject',
    label: CONDITIONS_LABEL['subject'],
    keyName: 'subject',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new SubjectRowManager(options, action, operatorValue)
  },
  {
    name: 'ticket_content',
    label: CONDITIONS_LABEL['ticket_content'],
    keyName: 'ticket_content',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new TicketContentRowManager(options, action, operatorValue)
  },
  {
    name: 'ticket_priority',
    label: CONDITIONS_LABEL['ticket_priority'],
    keyName: 'ticket_priority',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new PriorityRowManager(options, action, operatorValue)
  },
  {
    name: 'account_name',
    label: CONDITIONS_LABEL['account_name'],
    keyName: 'account_name',
    rowFactoryFn: (
      options: Option[],
      action?: Action,
      operatorValue?: string
    ) => new AccountNameRowManager(options, action, operatorValue)
  }
];

export const ACTIONS_RULE: Option[] = [
  {
    name: 'change_case_assignee',
    label: 'Assign agent',
    keyName: 'change_case_assignee', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AssignAgentRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_assignee_team',
    label: 'Assign team',
    keyName: 'change_case_assignee_team', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AssignTeamRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'change_case_status',
    label: 'Change case status',
    keyName: 'change_case_status', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new ChangeCaseStatusRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'reply_to_case',
    label: 'Auto-respond to customer',
    keyName: 'reply_to_case', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new AutoRespondRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'write_case_tags',
    label: 'Write tags to case',
    keyName: 'write_case_tags', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new WriteCaseTagsRowManager(options, value, operatorValue, 'action')
  },
  {
    name: 'write_case_custom_field',
    label: 'Write custom field to case',
    keyName: 'write_case_custom_field', // name from BE data
    rowFactoryFn: (options: Option[], value?: Action, operatorValue?: string) =>
      new WriteCaseCustomFieldRowManager(
        options,
        value,
        operatorValue,
        'action'
      )
  }
];
