import React from 'react';
import { Drawer } from '@material-ui/core';

export interface DetectedIncidentsSettingsDrawerProps {
  showDrawer: boolean;
}

export const DetectedIncidentsSettingsTableDrawer: React.FC<DetectedIncidentsSettingsDrawerProps> = (
  props
) => {
  return (
    <React.Fragment>
      <Drawer
        anchor="right"
        open={props.showDrawer}
        // onClose={toggleDrawer(false)}
        classes={{
          paperAnchorRight: 'trending-incidents-settings-drawer'
        }}
      >
        {props.children}
      </Drawer>
    </React.Fragment>
  );
};
