import React from 'react';
import './ProgressBar.scss';
import {
  Box,
  LinearProgress,
  LinearProgressProps,
  Typography
} from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';

export const LinearProgressBar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      height: 10,
      borderRadius: 5,
      width: '100%'
    },
    colorPrimary: {
      backgroundColor:
        theme.palette.grey[theme.palette.type === 'light' ? 200 : 700]
    },
    bar: {
      borderRadius: 5
    }
  })
)(LinearProgress);

export function LinearProgressBarWithLabel(
  props: LinearProgressProps & { value: number }
) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgressBar variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}
