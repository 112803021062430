import React, { FormEvent } from 'react';
import {
  IrisAgentButton,
  IrisAgentCancelButton
} from '../components/button/IrisAgentButton';
import { Stack } from '../components/common';
import { FormHelperText } from '@material-ui/core';
import { FormHelperTextProps } from '@material-ui/core/FormHelperText/FormHelperText';
import { Loader } from '../components/common/blocks/Loader';
import Utils from '../utils/utils';

interface PlatformConnectButtonsProps {
  onConnect: () => Promise<void>;
  onCancel: () => void;
}

export const PlatformConnectButtons = (props: PlatformConnectButtonsProps) => {
  const { onConnect, onCancel } = props;
  const [isLoading, setLoading] = React.useState(false);
  return (
    <React.Fragment>
      <Stack direction={'horizontal'}>
        <IrisAgentCancelButton
          size={'medium'}
          onClick={onCancel}
          classes={{ root: 'ml-auto' }}
        >
          Cancel
        </IrisAgentCancelButton>
        {/*Set the fixed width for the button to show `Connect` or the loader in the same space without disturbing
            the layout or UX
            */}
        <IrisAgentButton
          size={'medium'}
          type={'submit'}
          onClick={onConnectClick}
          style={{ width: '83px' }}
        >
          {isLoading ? <Loader imgClassName={'small-img-loader'} /> : 'Connect'}
        </IrisAgentButton>
      </Stack>
    </React.Fragment>
  );

  async function onConnectClick(event: FormEvent) {
    // Prevent the form from submitting when being used from the Platforms page <form/>.
    event.preventDefault();

    setLoading(true);
    try {
      await onConnect();
    } catch (e) {
      Utils.showUnknownError(e);
    } finally {
      setLoading(false);
    }
  }
};

export interface PlatformConnectFormControlTextProps
  extends FormHelperTextProps {
  message?: string;
}

export const PlatformConnectFormControlText: React.FC<PlatformConnectFormControlTextProps> = (
  props
) => {
  const { message, ...rest } = props;
  // If " " is provided, the component reserves one line height for displaying a future message.
  // Ref: https://v4.mui.com/api/form-helper-text/#props
  return <FormHelperText {...rest}>{message ? message : ' '}</FormHelperText>;
};
