import Utils from '../utils/utils';
import IrisPortalService from './IrisPortalService';

class StripeService {
  public static getAllInvoices = async () => {
    // Not being used anywhere right now, will be used in the future when making our own payment page
    let user = Utils.getObjectItem('user');
    let token_express = Utils.getObjectItem('token_express');
    const invoicesUrl = `${process.env.REACT_APP_BACKEND_URL}/stripe/getInvoicesForCustomer?customerId=${user.customerId}`;
    const response = await fetch(invoicesUrl, {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    });
    IrisPortalService.handleError(response.status);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  public static goToCustomerPortal = async (previousUrl: string) => {
    let user = Utils.getObjectItem('user');
    let token_express = Utils.getObjectItem('token_express');
    const invoicesUrl = `${process.env.REACT_APP_BACKEND_URL}/stripe/customerPortal?customerId=${user.customerId}&previousUrl=${previousUrl}`;
    const response = await fetch(invoicesUrl, {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    });
    IrisPortalService.handleError(response.status);
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    window.open(body.url, '_blank');
  };
}

export default StripeService;
