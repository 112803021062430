import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import { handleError, isMocKData } from './utils';
import Constants from '../../utils/constants';

export const oauthZendesk = async (subdomain: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ subdomain: subdomain })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/zendesk`,
    requestOptions
  );
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  window.open(body.uri, '_self');
};

export const oauthZendeskChat = async (subdomain: string) => {
  window.open(
    `${process.env.REACT_APP_BACKEND_URL}/passport/zd/chat/login?subdomain=${subdomain}`,
    '_self'
  );
};

export const oauthIntercom = async () => {
  window.open(`${process.env.REACT_APP_BACKEND_URL}/oauth/intercom`, '_self');
};

export const signupIrisGpt = async (helpCenterUrl: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ helpCenterUrl }),
    credentials: 'include'
  } as RequestInit;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/signup/irisGpt`,
    requestOptions
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Something went wrong while signing up`);
  }

  const body = await response.json();

  window.location.href = body.uri;
};

export const authFreshdesk = async (subdomain: string, accessToken: string) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ subdomain: subdomain, accessToken: accessToken }),
    credentials: 'include'
  } as RequestInit;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/freshdesk`,
    requestOptions
  );
  const body = await response.json();
  if (response.status !== 200) {
    let baseMessage = `Freshdesk token check failed with error code ${response.status}.
                         Check subdomain and API Key values.`;
    switch (response.status) {
      case 404:
      case 401:
      case 403:
        Utils.showNotify(
          'Looks like the subdomain or token you added is invalid. Can you try again?'
        );
        break;
      // case 404:
      //   baseMessage += `\n A 404 error code means the freshdesk URL was not found. Ensure your subdomain is
      //                     correct and follows the correct format specified.`;
      //   break;
      // case 401:
      //   baseMessage += `\n A 401 error code means that the access token entered is not able to fetch data from
      //                     freshdesk. Please ensure it has admin access.`;
      //   break;
      // case 403:
      //   baseMessage += `\n A 403 error code means that the access token does not have sufficient permissions to
      //                     fetch data from freshdesk. Please ensure it has admin access.`;
      //   break;
      default:
        Utils.showError(baseMessage);
    }
  } else {
    window.open(body.uri, '_self');
  }
};

export const onConnectJiraPrimary = async (
  jiraTokenValue: string,
  email: string,
  subdomain: string,
  onPrem: boolean
) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      subdomain: subdomain,
      email: email,
      jiraToken: jiraTokenValue,
      onPrem: onPrem
    }),
    credentials: 'include'
  } as RequestInit;
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/jira/token`,
    requestOptions
  );

  if (response.status !== 200) {
    // let baseMessage = `Jira token check failed with error code ${response.status}.
    //                    Check values entered in form. ${body.message}`;
    const fn = response.status >= 500 ? Utils.showError : Utils.showNotify;
    let baseMessage = `Jira token check failed. Please check values entered in form`;
    fn(baseMessage);
  } else {
    const body = await response.json();
    window.open(body.uri, '_self');
  }
};

export const authZoho = async (
  zohoRegion: string,
  clientId: string,
  clientSecret: string
) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({
      zohoRegion: zohoRegion,
      clientId: clientId,
      clientSecret: clientSecret
    })
  };
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/auth/zoho`,
    requestOptions
  );
  const body = await response.json();
  if (response.status !== 200) {
    let baseMessage = `Zoho token check failed with error code ${response.status}.
                       Check values.`;
    // switch (response.status) {
    //   case 404:
    //     baseMessage += `\n A 404 error code means the freshdesk URL was not found. Ensure your subdomain is
    //                       correct and follows the correct format specified.`;
    //     break;
    //   case 401:
    //     baseMessage += `\n A 401 error code means that the access token entered is not able to fetch data from
    //                       freshdesk. Please ensure it has admin access.`;
    //     break;
    //   case 403:
    //     baseMessage += `\n A 403 error code means that the access token does not have sufficient permissions to
    //                       fetch data from freshdesk. Please ensure it has admin access.`;
    //     break;
    // }
    Utils.showError(baseMessage);
  } else {
    window.open(body.uri, '_self');
  }
};

export const getSlackToken = async ({
  code,
  state
}: {
  code: string;
  state: string;
}) => {
  const tokenExpress = Utils.getObjectItem('token_express');
  const user = Utils.getObjectItem('user');

  const params = new URLSearchParams({
    code,
    state,
    customerId: user.customerId
  });
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/token/slack?${params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenExpress}`
      }
    }
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  const body: { status: boolean; message: string } = await response.json();
  return body;
};

export const verifySlackToken = async () => {
  if (Utils.isDemo()) {
    return MockData.isSlackTokenAvailableMockData();
  }
  const tokenExpress = Utils.getObjectItem('token_express');
  const user = Utils.getObjectItem('user');

  const params = new URLSearchParams({
    customerId: user.customerId
  });
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/verify/slack?${params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenExpress}`
      }
    }
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  const body: { isToken: boolean } = await response.json();
  return body;
};

export const verifySalesforceCrmToken = async () => {
  if (Utils.isDemo()) {
    return MockData.isGenericTokenAvailableMockData();
  }

  const tokenExpress = Utils.getObjectItem('token_express');
  const user = Utils.getObjectItem('user');

  const params = new URLSearchParams({
    customerId: user.customerId
  });
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/validate/salesforce_crm/token?${params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenExpress}`
      }
    }
  );

  handleError(response.status);

  if (!response.ok) {
    throw new Error('Some error occurred');
  }

  const body: { isToken: boolean } = await response.json();
  return body;
};

export const saveSalesforceCrmToken = async () => {
  const tokenExpress = Utils.getObjectItem('token_express');
  const user = Utils.getObjectItem('user');

  const params = new URLSearchParams({
    customerId: user.customerId,
    tokenSaveType: 'CRM'
  });
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/oauth/salesforce?${params}`,
    {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${tokenExpress}`
      }
    }
  );

  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  console.log(' saveSalesforceCrmToken response ', body);
  window.open(body.uri, '_self');
};

export const isJiraTokenAvailable = async () => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getSaveTokenMockData();
  }
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/validate/jira/token?customerId=${user.customerId}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    }
  );
  handleError(response.status);
  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const onConnectJiraSecondary = async (
  jiraTokenValue: string,
  email: string,
  subdomain: string,
  onPrem: boolean
) => {
  console.log(`jiraTokenValue: ${jiraTokenValue}, ${email}, ${subdomain}`);
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getSaveTokenMockData();
  }

  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token_express}`
    },
    body: JSON.stringify({
      jiraToken: jiraTokenValue,
      email: email,
      subdomain: subdomain,
      customerId: user.customerId,
      onPrem: onPrem
    })
  };

  // console.log('requestOptions ', requestOptions);
  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/save/jira/token`,
    requestOptions
  );

  // const body = await response.json();
  if (!response.ok) {
    // Utils.showError(`Jira token check failed with error code ${response.status}.
    //   Please re-check values entered in the form. ${body.message}`);
    const fn = response.status >= 500 ? Utils.showError : Utils.showNotify;
    fn(`Jira token check failed. Please re-check values entered in the form`);
  } else {
    window.location.pathname = Constants.routes.MANAGE_ACCOUNTS;
  }
};
