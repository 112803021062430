import React from 'react';
import './Tool.scss';
import { Tool } from './Tool';
import { ReactComponent as Tag } from '../../../../assets/tag.svg';
import { ReactComponent as AccountName } from '../../../../assets/AccountName.svg';
import { ReactComponent as CustomField } from '../../../../assets/CustomField.svg';
import { ReactComponent as FileMinus } from '../../../../assets/fileMinus.svg';
import { ReactComponent as BookOpen } from '../../../../assets/bookOpen.svg';
import { ReactComponent as Category } from '../../../../assets/Category.svg';
import { ReactComponent as Condition } from '../../../../assets/gitMerge.svg';

import { Option } from '../../types';

type Props = {
  conditionToolClicked: (condition: string) => void;
  toolItems: Option[];
};

export const ConditionTool: React.FC<Props> = (props) => {
  const headerIcon = () => {
    return <Condition stroke="#F2F2F2" />;
  };
  const getIcon = (iconName: string) => {
    switch (iconName) {
      case 'tags':
        return <Tag />;
      case 'ticket_priority':
        return <Tag />;
      case 'custom_field':
        return <CustomField />;
      case 'subject':
        return <BookOpen />;
      case 'ticket_content':
        return <FileMinus />;
      case 'account_name':
        return <AccountName />;
      case 'categories':
        return <Category />;
      default:
        return;
    }
  };

  return (
    <Tool
      headerColor="#5D5FEF"
      heading="Conditions"
      toolItems={props.toolItems}
      getIcon={getIcon}
      headerIcon={headerIcon}
      conditionToolClicked={props.conditionToolClicked}
    ></Tool>
  );
};
