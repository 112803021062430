import React, { FC, useState } from 'react';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import { Stack, Text } from '../../../components/common';
import { formatOpenCloseCases, useDateRangeQueryForAgentData } from '../utils';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import './Agent.scss';
import { Loader } from '../../../components/common/blocks/Loader';
import moment from 'moment';
import Utils from '../../../utils/utils';
import { DEFAULT_END_DATE, DEFAULT_START_DATE } from '../../../utils/constants';
import { DropdownSearch } from '../../../components/DropdownSearch';

const NO_TEAM_FILTER_STRING = 'All teams';
export const AgentsSummarySection: FC = (props) => {
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);

  let [currentTeamName, setCurrentTeamName] = useState<String | undefined>(
    undefined
  );
  let allTeamNames: string[] = [];

  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData(
    dateRange,
    currentTeamName
  );

  const summaryData = [
    {
      count: agentAnalyticsData?.summary?.mttrInSecs
        ? `${Utils.durationFormatReadable(
            moment.duration(agentAnalyticsData?.summary?.mttrInSecs, 'seconds')
          )}`
        : 0,
      description: 'Avg. Resolution time'
    },
    {
      count: formatOpenCloseCases(
        agentAnalyticsData?.summary?.numOpenCases,
        agentAnalyticsData?.summary?.numClosedCases
      ),
      description: 'Total open Cases vs Closed cases'
    },
    {
      count: agentAnalyticsData?.summary?.avgOpenCasesPerAgent
        ? agentAnalyticsData?.summary?.avgOpenCasesPerAgent
        : 0,
      description: 'Avg. no. of open cases per agent'
    }
  ];

  allTeamNames = agentAnalyticsData ? agentAnalyticsData.allTeamNames : [];

  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Agent Analytics Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };

  const MenuListAndDatePicker = () => {
    return (
      <Stack align="center" gap={20}>
        <DropdownSearch
          placeholder={NO_TEAM_FILTER_STRING}
          value={currentTeamName}
          onChange={(_, newValue) => {
            if (newValue) {
              setCurrentTeamName(newValue);
            } else {
              setCurrentTeamName(undefined);
            }
          }}
          options={allTeamNames}
        />
        <DateRangePicker value={dateRange} onChange={handleDateRangeChange} />
      </Stack>
    );
  };
  return (
    <Stack direction="vertical" gap={20}>
      <Stack justify="space-between" style={{ marginTop: 16 }}>
        <Text weight="semi-bolder" variant="p4" color="dark">
          Agent Summary
        </Text>
        <MenuListAndDatePicker />
      </Stack>
      <Stack
        direction="horizontal"
        justify="flex-start"
        gap={20}
        align="center"
        style={{ width: 'minmax(302px, 1fr)', paddingBottom: '30px' }}
      >
        {agentAnalyticsData === undefined ? (
          <div className="centered-loader">
            <Loader />
          </div>
        ) : (
          summaryData?.map((data) => (
            <Stack
              style={{
                padding: '20px 40px',
                borderRadius: 10,
                flex: 'initial',
                background:
                  'linear-gradient(0deg, rgba(94, 129, 244, 0.05), rgba(94, 129, 244, 0.05)), #FFFFFF'
              }}
              direction="vertical"
            >
              <Text
                variant="p5"
                weight="semi-bolder"
                style={{ color: '#1C1D21' }}
              >
                {data.count}
              </Text>
              <Text variant="p1" weight="regular" style={{ color: '#8181A5' }}>
                {data.description}
              </Text>
            </Stack>
          ))
        )}
      </Stack>
    </Stack>
  );
};
