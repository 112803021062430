import { endOfDay, startOfDay, subDays, subWeeks } from 'date-fns';
import { ReactComponent as AutoResponseImpact } from '../assets/AutoResponseImpact.svg';
import { ReactComponent as StatusChangeImpact } from '../assets/StatusChangeImpact.svg';
import { ReactComponent as AutoAssignedImpact } from '../assets/AutoAssignedImpact.svg';

const DETECTED_INCIDENTS_KEY = 'IrisAgent';
const JIRA_KEY = 'Jira';
const STARTER_CUSTOMER_ID = 'starterCustomerId';

type tColorRating = {
  [key: string]: string;
};

const ColorRating: tColorRating = {
  bad: '#EB5757',
  moderate: '#F2994A',
  good: '#03BB73'
};

export enum TriggerAction {
  REPLY_TO_CASE = 'reply_to_case',
  CHANGE_CASE_STATUS = 'change_case_status',
  CHANGE_CASE_ASSIGNEE = 'change_case_assignee',
  CHANGE_CASE_ASSIGNEE_TEAM = 'change_case_assignee_team'
}

export enum TriggerFact {
  TICKET_PRIORITY = 'ticket_priority'
}

const Constants = Object.freeze({
  ASCENDING_SORTING: 'asc',
  DESCENDENT_SORTING: 'desc',
  STARTER_CUSTOMER_ID: STARTER_CUSTOMER_ID,
  JIRA_KEY: JIRA_KEY,
  DETECTED_INCIDENTS_KEY: DETECTED_INCIDENTS_KEY,
  SUPPORT_IMPACT_INCIDENTS_KEY: 'mostSupportImpactIncidentsData',
  ACCEPTANCE_STATUS_PENDING: 'pending',
  ACCEPTANCE_STATUS_IGNORED: 'ignored',
  ACCEPTANCE_STATUS_ACCEPTED: 'accepted',
  THUMBS_UP: 'thumbsUp',
  THUMBS_DOWN: 'thumbsDown',
  VIEW_ALL_FILTER: 'View all',
  CASES_TYPE_QUERY_PARAM: 'casesType',
  SLACK_REDIRECT_URI_PATH: `/oauth/redirect-uri/slack`,
  incidentImagesMap: {
    [DETECTED_INCIDENTS_KEY]: '/images/irisagent.svg',
    [JIRA_KEY]: '/images/jira.svg'
  },

  routes: {
    ACCOUNT_DETAILS: '/accounts',
    AGENT_DETAILS: '/agent-detailed',
    AGENT_OVERVIEW: '/agent/overview',
    DASHBOARD: '/dashboard',
    DETAILED_VIEW: '/detailed-view',
    FREE_TRIAL_EXPIRED: '/free-trial-expired',
    GET_SLACK_TOKEN: '/get-slack-token',
    HEALTH_ACCOUNT: '/health/account',
    HEALTH_OVERVIEW: '/health/overview',
    ESCALATIONS: '/escalations',
    HOME: '/home',
    LOGIN: '/',
    MANAGE_ACCOUNTS: '/manage-accounts',
    MANAGE_ORGANIZATIONS: '/manage-organization',
    MANAGE_USERS: '/manage-users',
    MANAGE_CHATBOT: '/manage-chatbot',
    CATEGORIES_OVERVIEW: '/overview',
    PLATFORMS: '/platforms',
    INCIDENTS_DETAILS: '/incidents-details',
    TRIGGERS: '/triggers',
    TRIGGERS_DETAILS: '/trigger-details',
    NEEDS_ATTENTION: '/attention',
    IRIS_GPT: '/irisgpt',
    AUTOMATION_IMPACT: '/automation/impact',
    AUTOMATION_CATEGORIES: '/automation/categories',
    AUTOMATION_ROUTING: '/automation/routing',
    ARTICLE_SEARCH: '/article/search',
    OKTA_AUTH_REDIRECT: '/okta/auth/redirect',
    CHATBOT_ACTIVITY: '/chatbot-activity'
  },
  menuItem: {
    INSIGHTS: 'Insights',
    HEALTH_OVERVIEW: 'Customer Health',
    ESCALATIONS: 'Escalations',
    CATEGORIES: 'Categories',
    AUTOMATION: 'Automation',
    AUTOMATION_IMPACT: 'Impact',
    TRIGGERS: 'Triggers',
    AGENT_ANALYTICS: 'Agent Performance',
    ENGINEERING_HEALTH: 'Engineering Health',
    PRODUCT_QUALITY: 'Product Status',
    IRIS_GPT: 'Chatbot',
    MANAGE_CHATBOT: 'Get Started',
    ARTICLE_SEARCH: 'Intelligent Search',
    ROUTING: 'Routing',
    CHATBOT_ACTIVITY: 'Conversations'
  },
  eventName: {
    CATEGORIES: 'Overview View'
  },
  incidents_sections: {
    incidents_suggested: 'recent-incidents-suggested',
    trending_incidents: 'recent-incidents-trending',
    incidents_linked: 'recent-incidents-linked',
    cases_that_need_attention: 'recent-cases-attention'
  },
  CONNECT_WITH_JIRA_BUTTON_ID: 'jira-connect-button',

  INCIDENTS_DATE_FILTER_SEARCH_KEY: 'incident-date-range',
  INCIDENTS_PAGE_SEARCH_KEY: 'incident-page',

  LINKED_JIRA_DATE_FILTER_SEARCH_KEY: 'linked-jira-date-range',
  LINKED_JIRA_PAGE_SEARCH_KEY: 'linked-jira-page',

  SUGGESTED_JIRA_QUERY_PARAM: 'suggested',

  ACCOUNT_NAME_SEARCH_PARAM: 'accountName',

  AGENT_ID_SEARCH_PARAM: 'agentId',

  TRIGGER_ID_SEARCH_PARAM: 'triggerId',

  TRIGGER_GUIDE: 'guide',
  FILTERS_CATEGORIES: 'filtersCategories',

  ColorRating,

  TRIGGER_TEMPLATES: {
    staticTemplate1: {
      name: 'Trigger to alert support reps on Jira status change',
      rule: {
        type: 'compound',
        operator: 'and',
        operands: [
          { type: 'compound', operator: 'and', operands: [] },
          {
            type: 'compound',
            operator: 'or',
            operands: [
              {
                type: 'compound',
                operator: 'and',
                operands: [
                  {
                    type: 'simple',
                    operator: 'value_changed_to',
                    fact: 'linked_jira_status',
                    value: 'To Do'
                  }
                ]
              }
            ]
          }
        ]
      },
      actions: [{ value: '', type: 'reply_to_case' }]
    },
    staticTemplate2: {
      name: 'Trigger to auto-respond to customers',
      rule: {
        type: 'compound',
        operator: 'and',
        operands: [
          { type: 'compound', operator: 'and', operands: [] },
          {
            type: 'compound',
            operator: 'or',
            operands: [
              {
                type: 'compound',
                operator: 'and',
                operands: [
                  { type: 'simple', operator: 'in', fact: 'tags', value: [] }
                ]
              }
            ]
          }
        ]
      },
      actions: [{ value: '', type: 'reply_to_case' }]
    }
  },

  STORAGE_KEY: {
    CUSTOMER: 'customer',
    USER: 'user',
    TRIGGER_INDEX_DATA: 'trigger_index_data',
    DATE_RANGE_CATEGORIES_OVERVIEW: 'daterange_categories_overview',
    PAGES: 'pages'
  },

  GENERIC_ERROR_MESSAGE: 'Something went wrong. Please try again later.',

  TRIGGER_ACTION_GROUPS: [
    {
      actions: [TriggerAction.REPLY_TO_CASE],
      description: 'No of cases auto-responded',
      icon: AutoResponseImpact
    },
    {
      actions: [
        TriggerAction.CHANGE_CASE_ASSIGNEE,
        TriggerAction.CHANGE_CASE_ASSIGNEE_TEAM
      ],
      description: 'No of cases auto-assigned',
      icon: AutoAssignedImpact
    },
    {
      actions: [TriggerAction.CHANGE_CASE_STATUS],
      description: 'No of cases with status auto-updated',
      icon: StatusChangeImpact
    }
  ],

  TRIGGER_FORMAT_NUDGE:
    "Use {{ticket.requester.name}} to include customer name and {{agent.name}} to include agent name in your message. New feature - use {{irisgpt.case.answer}} to include IrisGPT's AI answer, whenever it is available. Note that the auto-response will only be sent if no agent has replied previously."
});

export default Constants;

export const DEFAULT_START_DATE = startOfDay(subWeeks(new Date(), 1));
export const START_DATE_LAST_30_DAYS = startOfDay(subDays(new Date(), 30));
export const DEFAULT_END_DATE = endOfDay(new Date());

export enum Source {
  IRIS_GPT = 'IrisGPT',
  ZENDESK = 'Zendesk',
  SALESFORCE = 'Salesforce'
}
