import React, { ReactElement } from 'react';

import {
  formatPrerequisitesData,
  RulesRowManager,
  useTriggersPrerequisites
} from '../../../utils';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as AccountName } from '../../../../../assets/AccountName.svg';
import '../styles.scss';
import SearchWidget from '../../../../categories/components/SearchWidget';
import { Tooltip } from '@material-ui/core';

/**
 * Row manager for Categories
 */
export class AccountNameRowManager extends RulesRowManager {
  constructor(options: Option[], actionValue: any, actionOperator: any) {
    super(options, actionValue, actionOperator);
    this.action.type = 'account_name';
    this.action.operator = actionOperator === undefined ? 'eq' : actionOperator;
  }

  setDefaultActionValue(): void {
    this.action.value = '';
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    const accountNameOptions = formatPrerequisitesData(
      prerequesitesData.triggersPrerequesites?.customerAccountNames || []
    );

    if (this.action.value === undefined) this.setDefaultActionValue();

    return (
      <div
        style={{ display: 'flex', flexDirection: 'column' }}
        className={'w-100'}
      >
        <div
          style={{ display: 'flex', flexDirection: 'row' }}
          className={'w-100'}
        >
          <AccountName />
          <p className="title">Account Name</p>
        </div>
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 8 }}>
          <p className="operator-text">is</p>
          <Tooltip title={this.action.value} placement="top">
            <div className={'search-selector'}>
              <SearchWidget
                isCustom={false}
                searchValue={this.action.value}
                dataList={accountNameOptions.map((cat) => {
                  return cat.name;
                })}
                onInputChange={(input: string) => {
                  this.action.value = input;
                  handleRowComponent(this.action);
                }}
              />

              {/* <RulesNativeSelect
              options={accountNameOptions}
              defaultValue={this.action.value}
              onChange={(e) => {
                this.action.value = e.target.value;
                handleRowComponent(this.action);
              }}
            /> */}
            </div>
          </Tooltip>
        </div>
      </div>
    );
  }
}
