import React, { useCallback, useEffect, useRef, useState } from 'react';

import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Heading, Stack } from '../../../components/common';
import { Paper } from '../../../components/home/Paper';
import Button from '@material-ui/core/Button';
import FormLabel from '@material-ui/core/FormLabel';

import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DoneOutlinedIcon from '@material-ui/icons/DoneOutlined';
import { RouteComponentProps, useHistory } from 'react-router';

import IrisPortalService from '../../../services/IrisPortalService';
import Utils from '../../../utils/utils';
import { RulesAction } from './RulesActions';
import { RulesTrigger } from './RulesTrigger';
import AddIcon from '@material-ui/icons/Add';
import { IconButton } from '../../../components/IconButton';
import { MoreVertOutlined } from '@material-ui/icons';
import { ContextMenu, IContextMenuRef } from '../../../components/ContextMenu';
import { ContextMenuItem } from '../../../components/ContextMenu/ContextMenuItem';
import {
  IModalConfirmationRef,
  ModalConfirmation
} from '../../../components/ModalConfirmation';
import { useURLSearchParams } from '../../../utils/useURLSearchParams';
import { ConditionContainer, Option, Trigger, TriggerStatus } from '../types';
import { ACTIONS_RULE, CONDITIONS_RULE } from '../constants';
import { ITriggerNameInputRef, TriggerNameInput } from './TriggerNameInput';

import { useTriggersPrerequisites } from '../../../routes/rules/utils';

import { scrollToTop } from '../../../utils/scrollToTop';
import { useResetPage } from '../../../hooks/useResetPage';
import { updateIndexCurrentTriggers, useTriggers } from '../utils';
import { validateTriggerData } from '../utils/validateTriggerData';
import { useTriggerRuleDetailsState } from '../hooks/useTriggerRuleDetailsState';

import { ActionTool } from './Tools/ActionTool';
import { ConditionTool } from './Tools/ConditionTool';
import _ from 'lodash';
import { ActionRowWrapper } from './RulesManager/ActionRow';
import { TriggersImpactNumbers } from '../../../components/home/TriggersImpactNumbers';
import Constants, { Source } from '../../../utils/constants';
import { TriggerActionCases } from './TriggerActionCases';
import './RuleDetails.scss';
import * as Sentry from '@sentry/react';
import { EventHint } from '@sentry/react';

function logTriggerValidationFailure() {
  Sentry.getCurrentHub().captureMessage('Trigger validation failed', 'error', {
    captureContext: {
      tags: {
        customerId: Utils.getCustomerId()
      }
    }
  } as EventHint);
}

export const RuleDetails: React.FC<RouteComponentProps> = () => {
  const modalConfirmationRef = useRef<IModalConfirmationRef>(null);
  const moreOptionsContextMenuRef = useRef<IContextMenuRef>(null);
  const moreOptionsContainerRef = useRef<HTMLDivElement>(null);
  const triggerNameInputRef = useRef<ITriggerNameInputRef>(null);

  const [isTriggerProcessing, setIsTriggerProcessing] = useState(false);

  const [conditionSelected, setConditionSelected] = useState(false);

  const user = Utils.getUserItem();
  const [conditionsRule, setConditionsRule] = useState(
    _.cloneDeep(
      CONDITIONS_RULE.filter(
        (condition) =>
          user.sourceName !== Source.SALESFORCE ||
          (condition.name !== 'tags' && condition.name !== 'ticket_priority')
      )
    )
  );

  const [actions, setActions] = useState(
    _.cloneDeep(
      ACTIONS_RULE.filter(
        (action) =>
          user.sourceName !== Source.SALESFORCE ||
          (action.name !== 'write_case_tags' &&
            action.name !== 'change_case_assignee_team')
      )
    )
  );

  const history = useHistory();
  const query = useURLSearchParams();

  const triggerId = query.get(Constants.TRIGGER_ID_SEARCH_PARAM) || undefined;

  const templateId = query.get('templateId') || undefined;

  const [
    triggerDetailCurrent,
    setTriggerDetailCurrent,
    refetchTriggerDetailCurrent
  ] = useTriggerRuleDetailsState(triggerId);

  const [isEditable, setEditable] = React.useState<boolean>(false);
  const isAdmin = Utils.amIAdmin();
  useEffect(() => {
    if (!isAdmin) {
      setEditable(false);
      return;
    }

    setEditable(
      _.isBoolean(triggerDetailCurrent.isSystem)
        ? !triggerDetailCurrent.isSystem
        : true
    );
  }, [triggerDetailCurrent, isAdmin]);

  const statusTitle = triggerId ? 'Trigger Details' : 'Add New Trigger';
  const statusTitleButton = triggerId ? 'Save' : 'Add New Trigger';

  const { triggersData } = useTriggers();

  const prerequisitesData = useTriggersPrerequisites();

  const getCategoryAutoRespondTriggerTemplate = (
    categoryVal: string | null
  ) => {
    return {
      name: `Trigger for category:${categoryVal} to auto-respond to customers`,
      rule: {
        type: 'compound',
        operator: 'and',
        operands: [
          { type: 'compound', operator: 'and', operands: [] },
          {
            type: 'compound',
            operator: 'or',
            operands: [
              {
                type: 'compound',
                operator: 'and',
                operands: [
                  {
                    type: 'simple',
                    operator: 'eq',
                    fact: 'category',
                    value: categoryVal
                  }
                ]
              }
            ]
          }
        ]
      },
      actions: [{ value: '', type: 'reply_to_case' }]
    };
  };

  useEffect(() => {
    if (templateId) {
      switch (templateId) {
        case 'staticTemplate1':
          setTriggerDetailCurrent(Constants.TRIGGER_TEMPLATES.staticTemplate1);
          return;
        case 'staticTemplate2':
          setTriggerDetailCurrent(Constants.TRIGGER_TEMPLATES.staticTemplate2);
          return;
        case 'categoryAutoRespond':
          const categoryVal = query.get('categoryVal');
          setTriggerDetailCurrent(
            getCategoryAutoRespondTriggerTemplate(categoryVal)
          );
          return;
      }
      return;
    }
  }, [templateId, setTriggerDetailCurrent, query]);

  useEffect(() => {
    if (triggerDetailCurrent.id || templateId !== undefined) {
      if (
        triggerDetailCurrent.rule.operands[0].operands.length > 0 ||
        triggerDetailCurrent.rule.operands[1].operands.length > 0
      ) {
        setConditionsRule([...conditionsRule]);
      }
    }
    setActions([...actions]);

    // eslint-disable-next-line
  }, [triggerDetailCurrent]);

  const validateRuleOperandsAndActions = useCallback(
    (operands: any, actions: any) => {
      if (!triggerDetailCurrent.name) {
        const message = 'Name is required';

        triggerNameInputRef.current?.setError(message);
        Utils.showNotify(message);

        scrollToTop();

        return false;
      }

      if (triggerNameInputRef.current?.hasError) {
        Utils.showNotify("There's an error with the name of the trigger");

        scrollToTop();

        return false;
      }

      if (!operands.length) {
        Utils.showNotify(
          'No triggers applied, check if you filled all the inputs correctly.'
        );

        return false;
      }

      if (!actions.length) {
        Utils.showNotify(
          'No actions applied, check if you filled all the inputs correctly.'
        );

        return false;
      }

      if (
        operands.some((operand: any) =>
          operand?.operands.some((oper: any) =>
            oper?.operands.some((op: any) => _.isEmpty(op))
          )
        )
      ) {
        Utils.showNotify(
          'Some operands have no value, check if you filled all the inputs correctly.'
        );

        return false;
      }

      if (actions.some((act: any) => _.isEmpty(act.data.value))) {
        Utils.showNotify(
          'Some actions have no value, check if you filled all the inputs correctly.'
        );

        return false;
      }

      return true;
    },
    [triggerDetailCurrent.name]
  );

  const updateNameTrigger = useCallback(
    (value: string) => {
      setTriggerDetailCurrent((oldState: object) => ({
        ...oldState,
        name: value
      }));
    },
    [setTriggerDetailCurrent]
  );

  const saveNewTrigger = useCallback(async () => {
    const {
      actions,
      rule: { operands }
    } = triggerDetailCurrent;

    const triggerActions = actions.map((action: any) => ({
      type: action.type,
      data: {
        value: action.value
      }
    }));
    console.log(operands);
    const triggerRuleOperands = operands
      .filter((operand: any) => operand.operands.length > 0)
      .map((operand: any) => ({
        ...operand,
        operands: operand.operands.map(
          ({ id, ...operandOperand }: any) => operandOperand
        )
      }));

    if (!validateRuleOperandsAndActions(triggerRuleOperands, triggerActions)) {
      return;
    }

    const body = {
      ...triggerDetailCurrent,
      rule: {
        ...triggerDetailCurrent.rule,
        operands: triggerRuleOperands
      },
      actions: triggerActions
    };

    if (!validateTriggerData(body.rule)) {
      logTriggerValidationFailure();
      Utils.showError('Something went wrong, please try again.');
      return;
    }

    try {
      await IrisPortalService.createTrigger(body);

      updateIndexCurrentTriggers();
      history.push(Constants.routes.TRIGGERS);
    } catch {
      Utils.showError('Something went wrong, please try again later.');
    }
  }, [history, triggerDetailCurrent, validateRuleOperandsAndActions]);

  const updateTrigger = useCallback(async () => {
    const {
      actions,
      rule: { operands }
    } = triggerDetailCurrent;

    const triggerActions = actions.map((action: any) => {
      const mappedAction: any = {
        type: action.type,
        data: { value: action.value }
      };

      if (typeof action.id === 'string') {
        mappedAction._id = action.id;
      }

      return mappedAction;
    });

    const triggerRuleOperands = operands.filter(
      (operand: any) => operand.operands.length > 0
    );

    if (!validateRuleOperandsAndActions(triggerRuleOperands, triggerActions)) {
      return;
    }

    const body = {
      ...triggerDetailCurrent,
      rule: {
        ...triggerDetailCurrent.rule,
        operands: triggerRuleOperands
      },
      actions: triggerActions
    };

    if (!validateTriggerData(body.rule)) {
      logTriggerValidationFailure();
      return;
    }

    try {
      await IrisPortalService.editTrigger(body);

      updateIndexCurrentTriggers();
      refetchTriggerDetailCurrent();

      history.push(Constants.routes.TRIGGERS);
    } catch {
      Utils.showError('Something went wrong, please try again later.');
    }
  }, [
    history,
    refetchTriggerDetailCurrent,
    triggerDetailCurrent,
    validateRuleOperandsAndActions
  ]);

  const actionsTrigger = useCallback(
    (action: string) => {
      switch (action) {
        case 'create':
          setIsTriggerProcessing(true);
          saveNewTrigger();
          setIsTriggerProcessing(false);
          break;
        case 'update':
          setIsTriggerProcessing(true);
          updateTrigger();
          setIsTriggerProcessing(false);
          break;
        case 'cancel':
          history.push(Constants.routes.TRIGGERS);
      }
    },
    [history, saveNewTrigger, updateTrigger]
  );

  const handleOptionClone = useCallback(
    async (newNameTrigger: string, trigger: Trigger) => {
      delete trigger.id;
      trigger.name = newNameTrigger;
      const statusNew = await IrisPortalService.createTrigger(trigger);
      if (statusNew.response.ok) {
        updateIndexCurrentTriggers();
        Utils.showNotify('Trigger Cloned');
        history.push(Constants.routes.TRIGGERS);
      }
    },
    [history]
  );

  const handleToggleTrigger = useCallback(
    async (triggerDE: Trigger) => {
      let statusUpdated = { field: 'status', value: 'disabled' };
      const triggerId = triggerDE.id || '';
      if (triggerDE.status === TriggerStatus.ENABLED) {
        statusUpdated.value = TriggerStatus.DISABLED;
      } else {
        statusUpdated.value = TriggerStatus.ENABLED;
      }

      const statusUpdate = await IrisPortalService.updateStatusTrigger(
        triggerId,
        statusUpdated
      );
      if (statusUpdate.ok) {
        updateIndexCurrentTriggers();
        Utils.showNotify('Trigger Status was Updated');
        history.push(Constants.routes.TRIGGERS);
      }
    },
    [history]
  );

  const handleOptionTrigger = useCallback(
    (actionType: string, trigger: any) => {
      switch (actionType) {
        case 'enabled':
        case 'disabled':
          handleToggleTrigger(trigger);

          break;
        case 'clone':
          const newNameTrigger = trigger.name.concat(' ', 'Clone');

          const checkTrigger = triggersData?.triggers.find(
            (trigger: any) => trigger.name === newNameTrigger
          );

          if (checkTrigger === undefined) {
            handleOptionClone(newNameTrigger, trigger);
          } else {
            Utils.showNotify(
              'The trigger was cloned or exist some with same name'
            );
          }

          break;
        case 'delete':
          modalConfirmationRef.current?.openModal();

          break;
      }
    },
    [handleOptionClone, handleToggleTrigger, triggersData]
  );

  const handleActionsChange = useCallback(
    (operator: string, conditionContainers: ConditionContainer[]) => {
      const actions = conditionContainers
        .map((container) =>
          container.actions.map((action) => {
            return action.rowManager.action;
          })
        )
        .flat();
      setTriggerDetailCurrent((oldState: any) => ({
        ...oldState,
        actions
      }));
    },
    [setTriggerDetailCurrent]
  );

  const handleConditionsChange = useCallback(
    (operator: string, conditions: ConditionContainer[]) => {
      const oldOperands = [...triggerDetailCurrent.rule.operands];
      const operatorIndex = oldOperands.findIndex(
        (operand: any) => operand.operator === operator
      );

      if (operatorIndex < 0) {
        return;
      }
      oldOperands[
        operatorIndex === 0 ? operatorIndex + 1 : operatorIndex - 1
      ].operands = [];

      console.log('conditions = ', conditions);
      oldOperands[operatorIndex].operands = conditions.map((condition: any) => {
        const simpleActions = condition.actions.map(
          (action: ActionRowWrapper) => ({
            id: action.rowManager.action.id,
            type: action.rowManager.action.expressionType
              ? action.rowManager.action.expressionType
              : 'simple',
            operator: action.rowManager.action.operator,
            fact: action.rowManager.action.type,
            value: action.rowManager.action.value
          })
        );

        const compoundActions = {
          type: 'compound',
          operator: condition.operator,
          operands: simpleActions
        };
        return compoundActions;
      });

      setTriggerDetailCurrent((oldState: any) => ({
        ...oldState,
        rule: {
          ...oldState.rule,
          operands: oldOperands
        }
      }));
      setConditionSelected(conditions.length > 0 ? true : false);
    },
    [setTriggerDetailCurrent, triggerDetailCurrent.rule.operands]
  );

  const handleMoreOptions = useCallback<
    React.MouseEventHandler<HTMLButtonElement>
  >((evt) => {
    moreOptionsContextMenuRef.current?.openContextMenu(
      evt.clientX,
      evt.clientY
    );
  }, []);

  const getCurrentlyConditionsTrigger = useCallback(() => {
    const dataPrerequisites = prerequisitesData.triggersPrerequesites;
    let conditionsFilter = conditionsRule;
    if (dataPrerequisites?.categories?.length === 0) {
      conditionsFilter = conditionsFilter.filter(
        (condition: Option) => condition.keyName !== 'category'
      );
    }
    if (dataPrerequisites?.tags?.length === 0) {
      conditionsFilter = conditionsFilter.filter(
        (condition: Option) => condition.keyName !== 'tags'
      );
    }
    return conditionsFilter;
  }, [prerequisitesData.triggersPrerequesites, conditionsRule]);

  const deleteCurrentTrigger = useCallback(async () => {
    const statusDelete = await IrisPortalService.deleteTrigger(triggerId || '');
    if (statusDelete.ok) {
      updateIndexCurrentTriggers();
      Utils.showNotify('Trigger Deleted');
      history.push(Constants.routes.TRIGGERS);
    }
  }, [history, triggerId]);

  const handleOnModalConfirmTriggerDelete = useCallback(() => {
    deleteCurrentTrigger();
  }, [deleteCurrentTrigger]);

  useResetPage();

  const goToTriggers = useCallback(() => {
    history.push(Constants.routes.TRIGGERS);
  }, [history]);

  const [editName, setEditName] = useState(false);
  const [conditionToolClicked, setConditionToolClicked] = useState('');
  const [triggersEmpty, setTriggersEmpty] = useState(false);
  const onEditName = useCallback(() => {
    console.log(editName);
    setEditName(!editName);
    console.log(editName);
  }, [editName]);

  const onConditionToolClicked = (condition: string) => {
    setConditionToolClicked(condition);
    setTriggersEmpty(false);
    setTimeout(() => {
      setConditionToolClicked('');
    }, 500);
  };
  const onTriggersEmpty = () => {
    setTriggersEmpty(true);
    setConditionsRule(_.cloneDeep(CONDITIONS_RULE));
    triggerDetailCurrent.actions = [];
    setTriggerDetailCurrent(triggerDetailCurrent);
  };

  return (
    <PageContainer>
      <Stack direction="vertical">
        <Stack align="center" wrap="nowrap" justify={'space-between'}>
          <Stack direction="horizontal">
            <IconButton
              className="back_button_details"
              icon={ArrowBackIcon}
              onClick={goToTriggers}
              backgroundColor="#5e81f4"
              size="sm"
            ></IconButton>

            <Heading color="light-dark" style={{ marginLeft: 12 }}>
              {statusTitle}
            </Heading>
          </Stack>
        </Stack>
      </Stack>
      <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <div className={'main-container'}>
          <div className="white-container">
            {/*<div style={{ padding: 16,background:"white",borderRadius: 12, width:'100%',marginBottom:20 }}>*/}
            <TriggersImpactNumbers
              invertColor={true}
              title="Trigger Activity"
              size="sm"
              titleSize="sm"
              triggerId={triggerId}
              isDetails={true}
            />
            {/*</div>*/}
            <Paper
              className="white-container trigger-paper"
              style={{ marginTop: 20, marginRight: 0 }}
            >
              <Stack direction="horizontal" className="child">
                {editName === false && (
                  <FormLabel
                    style={{
                      alignSelf: 'center',
                      color: 'black',
                      fontSize: 20
                    }}
                  >
                    {triggerDetailCurrent?.name === '' && 'New trigger 1'}{' '}
                    {triggerDetailCurrent?.name}
                  </FormLabel>
                )}
                {isEditable && editName === false && (
                  <IconButton
                    className="edit-button"
                    icon={EditOutlinedIcon}
                    onClick={onEditName}
                    size="sm"
                  ></IconButton>
                )}
                {editName === true && (
                  <TriggerNameInput
                    ref={triggerNameInputRef}
                    nameValue={triggerDetailCurrent?.name}
                    triggerId={triggerId}
                    triggersData={triggersData}
                    updateValue={updateNameTrigger}
                  />
                )}
                {editName === true && (
                  <IconButton
                    className="edit-button"
                    icon={DoneOutlinedIcon}
                    onClick={onEditName}
                    size="sm"
                  ></IconButton>
                )}
                {triggerId && triggerDetailCurrent.id && (
                  <Stack
                    align="center"
                    justify="flex-end"
                    style={{ width: 'fit-content', flex: 'auto' }}
                  >
                    <p className="created-by-text">
                      Created on :{' '}
                      {Utils.formatDate(
                        triggerDetailCurrent.createdAt,
                        'D MMM Y'
                      )}{' '}
                    </p>

                    {isEditable && (
                      <div>
                        <IconButton
                          size="sm"
                          title={'More options'}
                          icon={MoreVertOutlined}
                          onClick={handleMoreOptions}
                        />

                        <ContextMenu
                          className="rules-triggers-table-context-menu"
                          ref={moreOptionsContextMenuRef}
                          itemRef={moreOptionsContainerRef}
                        >
                          <ContextMenuItem
                            onClick={() =>
                              handleOptionTrigger('clone', triggerDetailCurrent)
                            }
                          >
                            Clone
                          </ContextMenuItem>

                          <ContextMenuItem
                            onClick={() =>
                              handleOptionTrigger(
                                TriggerStatus.ENABLED ? 'disabled' : 'enabled',
                                triggerDetailCurrent
                              )
                            }
                          >
                            {triggerDetailCurrent.status ===
                            TriggerStatus.ENABLED
                              ? 'Disable'
                              : 'Enable'}
                          </ContextMenuItem>

                          <ContextMenuItem
                            onClick={() =>
                              handleOptionTrigger(
                                'delete',
                                triggerDetailCurrent
                              )
                            }
                          >
                            Delete
                          </ContextMenuItem>
                        </ContextMenu>
                      </div>
                    )}
                  </Stack>
                )}
              </Stack>

              {triggerDetailCurrent && triggerDetailCurrent.rule && (
                <RulesTrigger
                  triggers={triggerDetailCurrent.rule}
                  options={getCurrentlyConditionsTrigger()}
                  handleActionsChange={handleConditionsChange}
                  conditionToolClicked={conditionToolClicked}
                  onTriggersEmpty={onTriggersEmpty}
                  isEditable={isEditable}
                />
              )}
              {triggerDetailCurrent &&
                triggerDetailCurrent.rule.operands.length > 0 &&
                !triggersEmpty &&
                (triggerDetailCurrent.actions.length > 0 ||
                  conditionSelected) && (
                  <RulesAction
                    actions={triggerDetailCurrent.actions}
                    options={actions}
                    handleActionsChange={handleActionsChange}
                    conditionToolClicked={conditionToolClicked}
                    triggersEmpty={triggersEmpty}
                    isEditable={isEditable}
                  />
                )}
              <Stack
                justify={'flex-end'}
                style={{ marginRight: '4%' }}
                className="footer_details_rule"
              >
                <Button
                  className="button_details_cancel"
                  variant="contained"
                  onClick={() => actionsTrigger('cancel')}
                >
                  Cancel
                </Button>

                {isEditable && (
                  <Button
                    disabled={isTriggerProcessing}
                    className="button_details"
                    variant="contained"
                    startIcon={
                      triggerId ? undefined : (
                        <AddIcon className="button_icon" />
                      )
                    }
                    onClick={() =>
                      actionsTrigger(triggerId ? 'update' : 'create')
                    }
                  >
                    {statusTitleButton}
                  </Button>
                )}
              </Stack>
            </Paper>
          </div>
          <div className="white-container" style={{ marginTop: '2%' }}>
            {triggerId && <TriggerActionCases triggerId={triggerId} />}
          </div>
        </div>

        {isEditable && (
          <div className={'condition-action-container'}>
            <ConditionTool
              conditionToolClicked={onConditionToolClicked}
              toolItems={conditionsRule}
            ></ConditionTool>
            <ActionTool
              toolItems={actions}
              conditionToolClicked={onConditionToolClicked}
            ></ActionTool>
          </div>
        )}
      </div>

      <ModalConfirmation
        ref={modalConfirmationRef}
        title={'Are you sure delete this Trigger?'}
        onConfirm={handleOnModalConfirmTriggerDelete}
        acceptButtonLabel={'Yes'}
        cancelButtonLabel={'No'}
      />
    </PageContainer>
  );
};
