import React from 'react';
import { TableRow as MUITableRow } from '@material-ui/core';

export const TableWithTabsRow: React.FC = (props) => {
  return (
    <>
      <MUITableRow className="table-with-tabs-row-table-item-container">
        {props.children}
      </MUITableRow>
    </>
  );
};
