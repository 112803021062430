import React, { FC, useEffect, useState } from 'react';
import {
  Heading,
  IssueId,
  Stack,
  Text,
  useTableSorters
} from '../../../components/common';
import { Case } from '../types';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import { format } from 'date-fns';
import TableHeadSorting from '../../../components/table-head-sorting/TableHeadSorting';
import { SentimentLabel, SentimentNumberToText } from './SentimentLabel';
import { TablePaper } from '../../../components/common/blocks/TablePaper';
import {
  AdvancedFilter,
  SelectedItem
} from '../../categories/components/filterComponent/AdvancedFilter';
import { CategoryFilter, CategoryFilterType } from '../../categories';
import { ReactComponent as ExternalLink } from '../../../assets/external-link.svg';
import { ReactComponent as ExternalLinkGrey } from '../../../assets/external-link-grey.svg';

import _ from 'lodash';
import { CSVLink } from 'react-csv';
import { Button } from '../../../components/home/Button';

export const RecentCasesTable: FC<{ cases: Case[]; tabView?: boolean }> = (
  props
) => {
  const [cases, setCases] = useState(props.cases);
  const { onSort, filterData, sortColumn, sortOrder } = useTableSorters({
    turnOffPagination: true
  });
  const [filters, setFilters] = useState<CategoryFilter[]>([]);
  useEffect(() => {
    if (props.cases.length > 0) {
      const caseSentimentFilter: CategoryFilter = {
        key: 'caseSentiment',
        label: 'Sentiment',
        type: CategoryFilterType.SELECT,
        values: []
      };
      const priorityFilter: CategoryFilter = {
        key: 'priority',
        label: 'Priority',
        type: CategoryFilterType.SELECT,
        values: []
      };
      let uniqueSentiments = new Set<string>();
      let uniquePriorities = new Set<string>();
      props.cases.map((item) => {
        uniqueSentiments.add(
          SentimentNumberToText(item.caseSentiment?.score) || ''
        );
        uniquePriorities.add(item.priority);
        return item;
      });
      Array.from(uniqueSentiments).map((sent) => {
        caseSentimentFilter.values.push({
          id: sent,
          isChecked: false,
          value: sent
        });
        return sent;
      });
      Array.from(uniquePriorities).map((prio) => {
        priorityFilter.values.push({ id: prio, isChecked: false, value: prio });
        return prio;
      });
      setFilters([caseSentimentFilter, priorityFilter]);
    }
  }, [props.cases]);

  function onFiltersChange(items: SelectedItem[]) {
    const newCasesForSentiment: Case[] = [];
    const newCasesForPriority: Case[] = [];
    let sentimentSelected = false;
    let prioritySelected = false;
    items.map((item) => {
      props.cases.filter((cs) => {
        switch (item.key) {
          case 'priority':
            if (item.values.includes(cs.priority)) {
              newCasesForPriority.push(cs);
            }
            prioritySelected = true;
            break;
          case 'caseSentiment':
            const currSentimentText = SentimentNumberToText(
              cs.caseSentiment.score
            );
            if (currSentimentText) {
              if (item.values.includes(currSentimentText)) {
                newCasesForSentiment.push(cs);
              }
            }
            sentimentSelected = true;
            break;
        }
        return cs;
      });
      return item;
    });
    let newCases: Case[] = [];
    if (sentimentSelected && prioritySelected) {
      newCases = _.intersection(newCasesForSentiment, newCasesForPriority);
    } else if (sentimentSelected) {
      newCases = newCasesForSentiment;
    } else {
      newCases = newCasesForPriority;
    }
    if (newCasesForSentiment.length === 0 && newCasesForPriority.length === 0) {
      setCases(props.cases);
      return;
    }
    setCases(newCases);
  }

  function buildRecentCasesCSV(data: any): any {
    return data.map((item: any) => {
      return {
        'Case#': item?.CaseNumber,
        Subject: item?.Subject,
        Sentiment: item?.caseSentiment?.score,
        Priority: item?.priority,
        'Date Created': item?.timeOfCreation,
        InstanceURL: item?.InstanceURL
      };
    });
  }

  return (
    <TablePaper>
      <Stack direction="vertical" gap={20}>
        <Stack>
          {props.tabView !== true && (
            <Heading style={{ marginTop: 16 }}>
              Recent Cases {cases.length ? `(${cases.length})` : null}
            </Heading>
          )}
          <div
            style={{
              marginLeft: 'auto',
              marginTop: props.tabView === true ? '0px' : '16px'
            }}
          >
            <Stack direction="horizontal" gap={10}>
              <AdvancedFilter
                onFiltersChange={onFiltersChange}
                filters={filters}
              />
              {filterData(cases).length > 0 && (
                <CSVLink
                  data={buildRecentCasesCSV(filterData(cases))}
                  filename={`irisagent_recent_cases.csv`}
                  style={{
                    marginLeft: 'auto',
                    width: '150px'
                  }}
                >
                  <Button variant="outlined">Export to CSV</Button>
                </CSVLink>
              )}
            </Stack>
          </div>
        </Stack>
        <TableContainer style={{ maxHeight: 400 }}>
          <Table stickyHeader>
            <TableHeadSorting
              order={sortOrder}
              orderByColumn={sortColumn}
              onRequestSort={(_, column) => onSort(column)}
              headCells={TableHeaderCells}
            />
            <TableBody>
              {cases.length === 0 && (
                <TableRow>
                  <TableCell colSpan={100} align="center">
                    <Text>No Data</Text>
                  </TableCell>
                </TableRow>
              )}
              {filterData(cases).map((recentCase) => {
                return (
                  <TableRow>
                    {/* Issue ID */}
                    <TableCell variant="body">
                      <IssueId color="#58508D">{recentCase.CaseNumber}</IssueId>
                    </TableCell>

                    {/* Subject */}
                    <TableCell variant="body">
                      <Text
                        variant="p2"
                        weight="regular"
                        color="dark"
                        lineClamp={4}
                      >
                        {recentCase.Subject}
                      </Text>
                    </TableCell>

                    {/* Sentiment */}
                    <TableCell variant="body" align="center">
                      <SentimentLabel
                        sentimentScore={recentCase.caseSentiment?.score}
                      />
                    </TableCell>

                    {/* Priority Level */}
                    <TableCell variant="body">
                      <Text variant="p2" weight="regular">
                        {recentCase.priority && recentCase.priority !== null
                          ? `${recentCase.priority}`
                          : ''}
                      </Text>
                    </TableCell>

                    {/* Date of Creation */}
                    <TableCell variant="body">
                      <Text variant="p2" weight="regular">
                        {format(
                          new Date(recentCase.timeOfCreation),
                          'MMM dd, yyyy'
                        )}
                      </Text>
                    </TableCell>

                    {/* External Link */}
                    <TableCell variant="body" style={{ padding: 0 }}>
                      {recentCase.InstanceURL ? (
                        <a
                          href={recentCase.InstanceURL}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <ExternalLink />
                        </a>
                      ) : (
                        <ExternalLinkGrey />
                      )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Stack>
    </TablePaper>
  );
};

const TableHeaderCells = [
  { id: 'CaseNumber', label: 'Case#', width: '20%', hasFilter: true },
  { id: 'Subject', label: 'Subject', width: '35%', hasFilter: true },
  {
    id: 'caseSentimentScore',
    label: 'Sentiment',
    width: '13%',
    hasFilter: true
  },
  { id: 'PriorityLevel', label: 'Priority', width: '12%', hasFilter: true },
  {
    id: 'timeOfCreation',
    label: 'Date Created',
    width: '15%',
    hasFilter: true
  },
  { id: 'InstanceURL', label: '', width: '5%', hasFilter: false }
];
