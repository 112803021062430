import React, { FC, useCallback } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Constants, { Source } from '../../../../utils/constants';
import { useHistory } from 'react-router-dom';
import { ReactComponent as Template1 } from '../../../../assets/trigger_tem_1.svg';

import { ReactComponent as Template2 } from '../../../../assets/trigger_tem_2.svg';
import './styles.scss';
import { Template } from './Template';
import IrisPortalService from '../../../../services/IrisPortalService';
import Switch from '@material-ui/core/Switch';
import { createStyles, Theme, withStyles } from '@material-ui/core';
import Utils from '../../../../utils/utils';
import { ReactComponent as Settings } from '../../../../assets/settings.svg';
import {
  AdvancedFilter,
  SelectedItem
} from '../../../categories/components/filterComponent/AdvancedFilter';
import {
  CategoryFilter,
  CategoryFilterType,
  FilterChecked
} from '../../../categories';
import _ from 'lodash';

const AntSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 40,
      height: 22,
      padding: 0,
      display: 'flex',
      borderRadius: '12px',
      marginBottom: '4px'
    },
    switchBase: {
      padding: 3,
      color: theme.palette.grey[500],
      '&$checked': {
        transform: 'translateX(18px)',
        color: theme.palette.common.white,
        '& + $track': {
          opacity: 1,
          backgroundColor: 'var(--template-color)',
          borderColor: 'var(--template-color)'
        }
      }
    },
    thumb: {
      width: 16,
      height: 16,
      boxShadow: 'none'
    },
    track: {
      border: `1px solid ${theme.palette.grey[500]}`,
      borderRadius: 22 / 2,
      opacity: 1,
      backgroundColor: theme.palette.common.white
    },
    checked: {}
  })
)(Switch);

interface CardProps extends React.ComponentProps<any> {
  icon: React.ReactElement;
}

function Card(props: CardProps) {
  return (
    <div className="create-button template">
      <div className={'create-button-icon'}>{props.icon}</div>

      {props.children}
    </div>
  );
}

interface TemplateCardProps extends React.ComponentProps<any> {
  checked?: boolean;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void;
}

function TemplateCard(props: TemplateCardProps) {
  return (
    <Card
      icon={
        <AntSwitch
          checked={props.checked}
          onChange={props.onChange}
          name="checkedB"
          classes={{
            root: 'toggle-icon'
          }}
        />
      }
    >
      {props.children}
    </Card>
  );
}

export const TemplatesOverview: FC<any> = (props) => {
  const history = useHistory();
  const goToActionsTrigger = useCallback(() => {
    history.push(Constants.routes.TRIGGERS_DETAILS);
  }, [history]);

  const [hasCaseDeflection, setCaseDeflection] = React.useState<boolean>(
    props.hasCaseDeflection
  );

  const [
    hasEnabledPrivateCommentSidebar,
    setPrivateCommentSidebar
  ] = React.useState<boolean>(false);

  const {
    data: customer,
    isLoading,
    refetch: refetchCustomer
  } = Utils.useCustomerQuery(true);

  // sectionKey -> index
  const [
    agentAssistSectionKeyToIndex,
    setAgentAssistSectionKeyToIndex
  ] = React.useState<{ [key: string]: number }>({});

  const agentAssistCategoryFilters: CategoryFilter[] = React.useMemo(() => {
    return [
      {
        key: 'section',
        label: 'Select fields to show/hide and drag them to reorder',
        type: CategoryFilterType.SELECT,
        values: customer?.agentAssistSettings?.allSections
          .sort((section1, section2) => {
            // Always show the selected ones first in the same order as they are selected
            // and the rest in the order they are defined in the settings
            const section1Index = _.findIndex(
              customer?.agentAssist?.sections,
              (section) => _.isEqual(section, section1.key)
            );
            const section2Index = _.findIndex(
              customer?.agentAssist?.sections,
              (section) => _.isEqual(section, section2.key)
            );

            if (section1Index >= 0 && section2Index >= 0) {
              return section1Index - section2Index;
            } else if (section1Index >= 0) {
              return -1;
            } else if (section2Index >= 0) {
              return 1;
            } else {
              return (
                agentAssistSectionKeyToIndex[section1.key] ||
                0 - agentAssistSectionKeyToIndex[section2.key] ||
                0
              );
            }
          })
          .map((section) => {
            return {
              id: section.label,
              value: section.label,
              isChecked: _.includes(
                customer?.agentAssist?.sections,
                section.key
              )
            } as FilterChecked;
          })
      } as CategoryFilter
    ];
  }, [customer, agentAssistSectionKeyToIndex]);

  React.useEffect(() => {
    if (customer?.privateCommentSidebar?.enabled) {
      setPrivateCommentSidebar(true);
    } else {
      setPrivateCommentSidebar(false);
    }

    // set agent assist section key to index
    setAgentAssistSectionKeyToIndex(
      _.chain(customer?.agentAssistSettings?.allSections)
        .map((section, index) => [section.key, index])
        .fromPairs()
        .value()
    );
  }, [customer]);

  function enableOrDisableCaseDeflectionTrigger(enable: boolean) {
    return async (event: React.ChangeEvent<HTMLInputElement>) => {
      event.stopPropagation();

      setCaseDeflection(enable);

      try {
        await IrisPortalService.createOrUpdateCaseDeflectionTrigger(enable);
        props.triggerRefetch();
      } catch (e) {
        setCaseDeflection(!enable);
        Utils.showUnknownError(e);
      }
    };
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        gap: '20px'
      }}
    >
      {!_.isEqual(customer?.sourceName, Source.IRIS_GPT) && (
        <TemplateCard
          checked={hasCaseDeflection}
          onChange={enableOrDisableCaseDeflectionTrigger(!hasCaseDeflection)}
        >
          <p> {hasCaseDeflection ? 'Disable' : 'Enable'} Case </p>
          <p>Deflection</p>
        </TemplateCard>
      )}

      {!isLoading && customer?.privateCommentSidebarSettings?.show && (
        <TemplateCard
          checked={hasEnabledPrivateCommentSidebar}
          onChange={() =>
            enableOrDisablePrivateCommentSidebar(
              !hasEnabledPrivateCommentSidebar
            )
          }
        >
          {customer?.privateCommentSidebarSettings?.showSections && (
            <AdvancedFilter
              onFiltersChange={updateSections}
              classes={{
                root: 'top-right'
              }}
              filters={[
                {
                  key: 'section',
                  label: 'Section',
                  type: CategoryFilterType.SELECT,
                  values: customer?.privateCommentSidebarSettings?.allSections.map(
                    (section) => {
                      return {
                        value: section.label,
                        isChecked: _.includes(
                          customer?.privateCommentSidebar?.sections,
                          section.key
                        )
                      } as FilterChecked;
                    }
                  )
                } as CategoryFilter
              ]}
            >
              <Settings />
            </AdvancedFilter>
          )}

          <p>
            {' '}
            {hasEnabledPrivateCommentSidebar
              ? 'Disable'
              : 'Enable'} Private{' '}
          </p>
          <p>Note with AI</p>
          <p>on Cases</p>
        </TemplateCard>
      )}

      {customer?.agentAssistSettings?.show && (
        <AdvancedFilter
          width={435}
          draggable={true}
          onFiltersChange={updateAgentAssistSections}
          filters={agentAssistCategoryFilters}
        >
          <Card icon={<Settings />}>
            <p>Configure Agent</p>
            <p>Assist App</p>
          </Card>
        </AdvancedFilter>
      )}

      <div className="dash"></div>

      <div onClick={goToActionsTrigger} id="guide-createNewTrigger">
        <Card
          icon={
            <AddIcon
              style={{
                width: '30px',
                height: '30px'
              }}
            />
          }
        >
          <p> Create New </p>
          <p>Trigger</p>
        </Card>
      </div>

      <Template
        name="template1"
        img={<Template1 style={{ borderRadius: '12px' }} />}
      />
      <Template
        name="template2"
        img={<Template2 style={{ borderRadius: '12px' }} />}
      />
    </div>
  );

  async function updateSections(items: SelectedItem[]) {
    const sectionLabels = _.chain(items).first().get('values').value();
    if (_.isEmpty(sectionLabels)) {
      return enableOrDisablePrivateCommentSidebar(false);
    }

    const sectionLabelToKeyMap = _.chain(
      customer?.privateCommentSidebarSettings?.allSections
    )
      .keyBy('label')
      .mapValues('key')
      .value();

    const sections = sectionLabels.map((label) => sectionLabelToKeyMap[label]);

    try {
      await IrisPortalService.updatePrivateCommentSidebarSettings({
        action: 'update',
        sections
      });
      await refetchCustomer();
    } catch (e) {
      console.error(e);
    }
  }

  async function updateAgentAssistSections(items: SelectedItem[]) {
    if (_.isEmpty(items)) {
      Utils.showNotify(`At least one section should be selected`);
      return;
    }

    const sectionLabels = _.chain(items).first().get('values').value();
    if (_.isEmpty(sectionLabels)) {
      Utils.showNotify(`At least one section should be selected`);
      return;
    }

    const sectionLabelToKeyMap = _.chain(
      customer?.agentAssistSettings?.allSections
    )
      .keyBy('label')
      .mapValues('key')
      .value();

    const sections = sectionLabels.map((label) => sectionLabelToKeyMap[label]);

    try {
      await IrisPortalService.updateAgentAssistSettings(sections);
      await refetchCustomer();
    } catch (e) {
      console.error(e);
    }
  }

  async function enableOrDisablePrivateCommentSidebar(enable: boolean) {
    try {
      setPrivateCommentSidebar(enable);

      await IrisPortalService.updatePrivateCommentSidebarSettings({
        action: enable ? 'enable' : 'disable'
      });

      await refetchCustomer();
    } catch (e) {
      Utils.showUnknownError(e);
      setPrivateCommentSidebar(!enable);
    }
  }
};
