import React, { Component } from 'react';
import './ManageAccounts.scss';
import { Button } from '@material-ui/core';
import IrisPortalService from '../../services/IrisPortalService';
import AlertComponent from '../../components/alert-component/AlertComponent';
import ConnectJira from './connect-jira/ConnectJira';
import ConnectJiraServer from './connect-jira/ConnectJiraServer';
import Constants from '../../utils/constants';
import { RouteComponentProps } from 'react-router';
import { PageContainer } from '../../components/common/blocks/PageContainer';
import { Heading, Stack } from '../../components/common';
import { Text } from '../../components/common/blocks/Text';
import { TablePaper } from '../../components/common/blocks/TablePaper';
import { AtlassianConfig } from './AtlassianConfig';

class ManageAccounts extends Component<RouteComponentProps, any> {
  _isMounted = false; // To know when component is ready
  constructor(props: any) {
    super(props);
    this.state = {
      showNotification: false,
      inserted: false,
      jiraTokenExists: false,
      slackTokenExists: false,
      connectJira: false,
      connectJiraServer: false,
      notificationMessage: '',
      salesforceCrmTokenExists: false,
      isMockData: false
    };
  }

  componentDidMount = async () => {
    this.setState({ isMockData: process.env.REACT_APP_MOCKDATA });
    this._isMounted = true;

    await this.areTokensAvailable();
  };

  areTokensAvailable = async () => {
    let isJiraTokenAvailable = await IrisPortalService.isJiraTokenAvailable();
    let isSlackTokenAvailable = await IrisPortalService.verifySlackToken();
    let salesforceCrmTokenAvailable = await IrisPortalService.verifySalesforceCrmToken();

    console.log('isJiraTokenAvailable ', isJiraTokenAvailable);
    console.log('isSlackTokenAvailable ', isSlackTokenAvailable);
    console.log('salesforceCrmTokenAvailable', salesforceCrmTokenAvailable);

    if (isJiraTokenAvailable.isToken && this._isMounted) {
      this.setState({ jiraTokenExists: true });
    }
    if (isSlackTokenAvailable.isToken && this._isMounted) {
      this.setState({ slackTokenExists: true });
    }
    if (salesforceCrmTokenAvailable.isToken && this._isMounted) {
      this.setState({ salesforceCrmTokenExists: true });
    }
  };

  closeAlert = () => {
    this.setState({
      showNotification: false,
      inserted: false,
      notificationMessage: ''
    });
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  connectJira = () => {
    if (!this.state.jiraTokenExists) {
      this.setState({ connectJira: !this.state.connectJira });
    }
  };

  connectJiraServer = () => {
    if (!this.state.jiraTokenExists) {
      this.setState({ connectJiraServer: !this.state.connectJiraServer });
    }
  };

  connectSlack = () => {
    if (!this.state.slackTokenExists) {
      const SLACK_REDIRECT_URI_HOST = process.env.REACT_APP_BACKEND_URL;
      const SLACK_REDIRECT_URI = `${SLACK_REDIRECT_URI_HOST}${Constants.SLACK_REDIRECT_URI_PATH}`;
      const url = `https://slack.com/oauth/v2/authorize?scope=incoming-webhook,chat:write&client_id=1286901314787.2125467208005&redirect_uri=${SLACK_REDIRECT_URI}`;
      window.open(url, '_blank');
    }
  };

  connectSalesforceCrm = () => {
    if (!this.state.salesforceCrmTokenExists) {
      IrisPortalService.saveSalesforceCrmToken();
    }
  };

  updateConnectJira = (connectJira: boolean, message: string) => {
    this.setState({
      connectJira: connectJira,
      jiraTokenExists: !connectJira
    });
    this.showNotificationJira(connectJira, message);
  };

  updateConnectJiraServer = (connectJiraServer: boolean, message: string) => {
    this.setState({
      connectJiraServer: connectJiraServer,
      jiraTokenExists: !connectJiraServer
    });
    this.showNotificationJira(connectJiraServer, message);
  };

  showNotificationJira = (jiraBoolean: boolean, message: string) => {
    if (this._isMounted)
      this.setState({
        showNotification: true,
        inserted: !jiraBoolean,
        notificationMessage: message
      });
  };

  render() {
    const {
      showNotification,
      inserted,
      notificationMessage,
      connectJira,
      connectJiraServer,
      jiraTokenExists,
      slackTokenExists,
      salesforceCrmTokenExists
    } = this.state;

    return (
      <PageContainer>
        {showNotification && (
          <AlertComponent
            alertType={inserted ? 'success' : 'error'}
            message={notificationMessage}
            onClick={this.closeAlert}
          />
        )}
        <TablePaper>
          <Stack style={{ padding: 16, paddingLeft: 0 }}>
            <Heading>Manage Integrations</Heading>
          </Stack>
          <div className="manage-accounts-container">
            {connectJira && (
              <ConnectJira
                isPrimaryConnection={false}
                onClick={this.connectJira}
                updateConnectJira={this.updateConnectJira}
              />
            )}
            {connectJiraServer && (
              <ConnectJiraServer
                onClick={this.connectJiraServer}
                updateConnectJira={this.updateConnectJiraServer}
              />
            )}
            {!connectJira && !connectJiraServer && (
              <div className="container-card-account">
                <div className="card-account">
                  {jiraTokenExists && <AtlassianConfig />}

                  <div className="card-image">
                    <img src="/images/jira.png" alt="Platform" />
                  </div>
                  <Text
                    variant="p3"
                    weight="semi-bolder"
                    color="dark"
                    style={{
                      display: 'block',
                      paddingLeft: '15px',
                      paddingBottom: '10px'
                    }}
                  >
                    Atlassian/Jira Cloud
                  </Text>
                  <Text
                    variant="p0"
                    weight="regular"
                    color="gray"
                    style={{
                      boxSizing: 'border-box',
                      display: 'block',
                      paddingLeft: '15px',
                      height: '38px'
                    }}
                  >
                    Jira is a proprietary issue tracking product developed by
                    Atlassian that allows bug tracking.
                  </Text>
                  <Button
                    className={`button incident-source-button ${
                      jiraTokenExists ? 'disable-button' : ''
                    } `}
                    fullWidth
                    onClick={async () => {
                      this.connectJira();
                    }}
                    id={Constants.CONNECT_WITH_JIRA_BUTTON_ID}
                  >
                    {jiraTokenExists ? 'Connected' : 'Connect'}
                  </Button>
                </div>
                <div className="card-account">
                  <div className="card-image">
                    <img src="/images/jira.png" alt="Platform" />
                  </div>
                  <Text
                    variant="p3"
                    weight="semi-bolder"
                    color="dark"
                    style={{
                      display: 'block',
                      paddingLeft: '15px',
                      paddingBottom: '10px'
                    }}
                  >
                    Atlassian/Jira Server
                  </Text>
                  <Text
                    variant="p0"
                    weight="regular"
                    color="gray"
                    style={{
                      boxSizing: 'border-box',
                      display: 'block',
                      paddingLeft: '15px',
                      height: '38px'
                    }}
                  >
                    Jira is a proprietary issue tracking product developed by
                    Atlassian that allows bug tracking.
                  </Text>
                  <Button
                    className={`button incident-source-button ${
                      jiraTokenExists ? 'disable-button' : ''
                    } `}
                    fullWidth
                    onClick={async () => {
                      this.connectJiraServer();
                    }}
                    id={Constants.CONNECT_WITH_JIRA_BUTTON_ID}
                  >
                    {jiraTokenExists ? 'Connected' : 'Connect'}
                  </Button>
                </div>
                <div className="card-account">
                  <div className="card-image">
                    <img
                      src="/images/salesforce.svg"
                      alt="Platform"
                      width={'120px'}
                      height={'60px'}
                    />
                  </div>
                  <Text
                    variant="p3"
                    weight="semi-bolder"
                    color="dark"
                    style={{
                      display: 'block',
                      paddingLeft: '15px',
                      paddingBottom: '10px'
                    }}
                  >
                    Salesforce CRM
                  </Text>
                  <Text
                    variant="p0"
                    weight="regular"
                    color="gray"
                    style={{
                      boxSizing: 'border-box',
                      display: 'block',
                      paddingLeft: '15px',
                      height: '38px'
                    }}
                  >
                    Connect your salesforce account to link sales data.
                  </Text>
                  <Button
                    className={`button incident-source-button ${
                      salesforceCrmTokenExists ? 'disable-button' : ''
                    } `}
                    // fullWidth
                    onClick={async () => {
                      this.connectSalesforceCrm();
                    }}
                  >
                    {salesforceCrmTokenExists ? 'Connected' : 'Connect'}
                  </Button>
                </div>
                <div className="card-account">
                  <div className="card-image">
                    <img src="/images/slack.png" alt="Platform" />
                  </div>
                  <Text
                    variant="p3"
                    weight="semi-bolder"
                    color="dark"
                    style={{
                      display: 'block',
                      paddingLeft: '15px',
                      paddingBottom: '10px'
                    }}
                  >
                    Slack
                  </Text>
                  <Text
                    variant="p0"
                    weight="regular"
                    color="gray"
                    style={{
                      boxSizing: 'border-box',
                      display: 'block',
                      paddingLeft: '15px',
                      height: '38px'
                    }}
                  >
                    A new way to communicate with team. It's faster, better
                    organised and more secure than email.
                  </Text>
                  <Button
                    className={`button incident-source-button ${
                      slackTokenExists ? 'disable-button' : ''
                    } `}
                    // fullWidth
                    onClick={async () => {
                      this.connectSlack();
                    }}
                  >
                    {slackTokenExists ? 'Connected' : 'Connect'}
                  </Button>
                </div>

                {this.state.isMockData && (
                  <div className="card-account">
                    <div className="card-image">
                      <img
                        src="/images/hubspot.webp"
                        alt="Platform"
                        width={'120px'}
                        height={'60px'}
                      />
                    </div>
                    <Text
                      variant="p3"
                      weight="semi-bolder"
                      color="dark"
                      style={{
                        display: 'block',
                        paddingLeft: '15px',
                        paddingBottom: '10px'
                      }}
                    >
                      Hubspot
                    </Text>
                    <Text
                      variant="p0"
                      weight="regular"
                      color="gray"
                      style={{
                        boxSizing: 'border-box',
                        display: 'block',
                        paddingLeft: '15px',
                        height: '38px'
                      }}
                    >
                      A marketing, sales, and customer service platform that
                      helps companies grow better.
                    </Text>
                    <Button
                      className={`button incident-source-button  `}
                      // fullWidth
                    >
                      Connect
                    </Button>
                  </div>
                )}
              </div>
            )}
          </div>
        </TablePaper>
      </PageContainer>
    );
  }
}

export default ManageAccounts;
