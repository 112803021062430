import React, { forwardRef } from 'react';
import { Text, TextProps } from './Text';

interface HeadingProps extends Omit<TextProps, 'ref'> {}

export const Heading = forwardRef<HTMLSpanElement, HeadingProps>(
  (props, ref) => {
    return <Text variant="p4" weight="semi-bolder" color="dark" {...props} />;
  }
);
