import React from 'react';
import { Stack } from '../../components/common';
import { CustomerAccountHealthScoreTable } from '../customerHealth/components/CustomerAccountHealthScoreTable';
import { Loader } from '../../components/common/blocks/Loader';
import { TablePaper } from '../../components/common/blocks/TablePaper';
import { CustomerCasesTable } from '../customerHealth/components/CustomerCasesTable';
import { useCustomerHealthData } from '../customerHealth/hooks/useCustomerHealthData';

export const CustomerHealthScoreAndCases: React.FC = () => {
  const {
    dataCustomerHealth,
    isFetchingCustomerHealth
  } = useCustomerHealthData();

  return (
    <React.Fragment>
      <Stack gap={35} direction="vertical">
        <Stack direction="vertical" gap={60}>
          <Stack
            direction="horizontal"
            gap={30}
            style={{ width: '100%', padding: '0px !important' }}
          >
            <div style={{ width: '50%' }}>
              {!isFetchingCustomerHealth ? (
                <CustomerAccountHealthScoreTable
                  data={
                    dataCustomerHealth?.customerHealth
                      .customerLowestHealthScores || []
                  }
                />
              ) : (
                <Loader center />
              )}
            </div>
            <TablePaper style={{ width: '50%' }}>
              {!isFetchingCustomerHealth ? (
                <CustomerCasesTable
                  data={
                    dataCustomerHealth?.customerHealth.customerWithNumCases ||
                    []
                  }
                  title="Customers with most cases in last month"
                  color="#1AD89F"
                />
              ) : (
                <Loader center />
              )}
            </TablePaper>
          </Stack>
        </Stack>
      </Stack>
    </React.Fragment>
  );
};
