import { Heading, Stack } from './common';
import { CasesWithLinkedJiraTable } from '../routes/incidents/incident-list/CasesWithLinkedJiraTable';
import { TablePaper } from './common/blocks/TablePaper';
import React from 'react';
import Utils from '../utils/utils';

export interface PrioritizedEngineeringEscalationsProps
  extends React.ComponentProps<any> {
  title?: string;
}

export const PrioritizedEngineeringEscalations: React.FC<PrioritizedEngineeringEscalationsProps> = (
  props: PrioritizedEngineeringEscalationsProps
) => {
  const { title = 'Prioritized engineering escalations' } = props;

  if (!Utils.checkOnboardedValue()) {
    return <React.Fragment></React.Fragment>;
  }

  return (
    <React.Fragment>
      <TablePaper style={{ marginTop: '2%' }}>
        <Stack direction="vertical" gap={20}>
          <Stack
            direction={'vertical'}
            gap={10}
            style={{
              alignItems: 'baseline',
              paddingTop: 16
            }}
          >
            <Heading>{title}</Heading>
          </Stack>

          <CasesWithLinkedJiraTable />
        </Stack>
      </TablePaper>
    </React.Fragment>
  );
};
