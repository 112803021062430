import {
  createProblemTicket,
  getAgentAnalyticsData,
  getChangeIrisportalviewUserData,
  getFirstTimeSetupIrisPortal,
  getIrisPortalInvitedUsers,
  getSingleAgentAnalytics,
  saveIrisPortalUser
} from './common';
import { handleError } from './utils';
import { getJIRAIssueDetails } from './jira';
import { getKnowledgeBaseArticles } from './topics';
import { getCaseCategoriesData, getCaseCategoryFilters } from './categories';
import {
  getAccountHealth,
  getAllAccountNames,
  getCustomer,
  getCustomerHealthDataExtras,
  getCustomerHealthDataV2,
  getDetailsAccountDashboardIrisPortal,
  getRecentCasesForAccount,
  getCustomerEngagement,
  updatePrivateCommentSidebarSettings,
  updateAgentAssistSettings,
  getAtlassianConfig,
  updateAtlassianConfig
} from './customer';
import {
  getAiRecommendedCases,
  getIncidentDetails,
  getIncidentSourcesData,
  subscribeToDetectedIncidents,
  updateAcceptanceStatus
} from './incidents';
import {
  authFreshdesk,
  authZoho,
  getSlackToken,
  isJiraTokenAvailable,
  oauthIntercom,
  oauthZendesk,
  oauthZendeskChat,
  onConnectJiraPrimary,
  onConnectJiraSecondary,
  saveSalesforceCrmToken,
  signupIrisGpt,
  verifySalesforceCrmToken,
  verifySlackToken
} from './connect';
import {
  getUserInfo,
  onLoginGoogle,
  onLoginMicrosoft,
  onLoginSalesforce,
  onLoginZendesk
} from './auth';
import {
  getAttributeValueCaseDrivers,
  getCustomersWithLowestHealthScores,
  getDashboardSummary,
  getImportantSupportMetrics,
  getRevenueImpact
} from './home';
import {
  createTrigger,
  deleteTrigger,
  editTrigger,
  getTrigger,
  getTriggers,
  getTriggersPrerequisites,
  updateStatusTrigger,
  getTriggersOverallImpactData,
  getTriggerImpactData,
  getTriggerActionCases,
  createOrUpdateCaseDeflectionTrigger,
  getTriggersOverallImpactTrendData
} from './triggers';
import { getIndexData, sendIrisGPTMessage } from './irisGPT';
import {
  getDetectedIncidentsSettingsPrerequisites,
  getDetectedIncidentSettings,
  createDetectedIncidentSetting,
  editDetectedIncidentSetting,
  deleteDetectedIncidentSetting
} from './detectedIncidentsSettings';
import {
  createChatbotSetting,
  getChatbotSetting,
  editChatbotSetting
} from './chatbotSettings';
import { getArticleMetrics } from './automation';
import { getChatbotActivity } from './chatbotActivity';
import { getUserData } from './user';

export const IrisPortalService = {
  onLoginSalesforce,
  oauthZendesk,
  oauthIntercom,
  authFreshdesk,
  oauthZendeskChat,
  authZoho,
  onLoginZendesk,
  onLoginGoogle,
  getUserInfo,
  getAllAccountNames,
  getDetailsAccountDashboardIrisPortal,
  getRecentCasesForAccount,
  getAccountHealth,
  getFirstTimeSetupIrisPortal,
  getKnowledgeBaseArticles,
  getIncidentSourcesData,
  getIncidentDetails,
  getJIRAIssueDetails,
  getCaseCategoriesData,
  getCustomerHealthDataV2,
  getCustomerHealthDataExtras,
  saveIrisPortalUser,
  getIrisPortalInvitedUsers,
  // getAgentAnalytics,
  getAgentAnalyticsData,
  getSingleAgentAnalytics,
  getTrigger,
  getTriggers,
  getTriggersPrerequisites,
  createTrigger,
  editTrigger,
  updateStatusTrigger,
  deleteTrigger,
  getTriggersOverallImpactData,
  getTriggerImpactData,
  getTriggerActionCases,
  createProblemTicket,
  handleError,
  isJiraTokenAvailable,
  onConnectJiraPrimary,
  onConnectJiraSecondary,
  getChangeIrisportalviewUserData,
  updateAcceptanceStatus,
  getSlackToken,
  verifySlackToken,
  getAiRecommendedCases,
  getDashboardSummary,
  getCustomersWithLowestHealthScores,
  getImportantSupportMetrics,
  getRevenueImpact,
  onLoginMicrosoft,
  getCaseCategoryFilters,
  getUserData,
  subscribeToDetectedIncidents,
  getAttributeValueCaseDrivers,
  getCustomer,
  verifySalesforceCrmToken,
  saveSalesforceCrmToken,
  getCustomerEngagement,
  signupIrisGpt,
  createOrUpdateCaseDeflectionTrigger,
  getIndexData,
  updatePrivateCommentSidebarSettings,
  sendIrisGPTMessage,
  getDetectedIncidentsSettingsPrerequisites,
  getDetectedIncidentSettings,
  createDetectedIncidentSetting,
  editDetectedIncidentSetting,
  deleteDetectedIncidentSetting,
  createChatbotSetting,
  getChatbotSetting,
  editChatbotSetting,
  getArticleMetrics,
  getTriggersOverallImpactTrendData,
  updateAgentAssistSettings,
  getAtlassianConfig,
  updateAtlassianConfig,
  getChatbotActivity
};
export default IrisPortalService;
