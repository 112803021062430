import {
  Button,
  createStyles,
  withStyles,
  ButtonProps
} from '@material-ui/core';
import React from 'react';

export const IrisAgentButton = withStyles(
  createStyles({
    root: {
      background: `linear-gradient(
                0deg,
            rgba(94, 129, 244, 0.1),
        rgba(94, 129, 244, 0.1)
    ),
    #ffffff`,
      borderRadius: '8px !important',
      color: '#5d5fef !important'
    }
  })
)(Button);

export const IrisAgentCancelButton = (props: ButtonProps) => {
  return <Button {...props} color={'secondary'}></Button>;
};
