import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import Constants from '../../utils/constants';
import { handleError, isMocKData } from './utils';

export const getJIRAIssueDetails = async (
  issueKey: string,
  casesType?: string
) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getJiraIssuesMockData().jiraIssues[0];
  }

  const params = {
    customerId: user.customerId,
    issueKey,
    ...(casesType && { [Constants.CASES_TYPE_QUERY_PARAM]: casesType })
  };
  const url = `${
    process.env.REACT_APP_BACKEND_URL
  }/dashboard/getJiraIssuesDetails?${new URLSearchParams(params)}`;

  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });

  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};
