import { CaseDistributionOptionStyle, DistributionColorStyle } from './types';

export const YellowDistributionColor: DistributionColorStyle = {
  color: '#F3C94C',
  background: '#E7EAE8',
  gradientColor: { first: '#F3C94C', last: '#E7EAE8' }
};

export const AgentDistributionLabels: CaseDistributionOptionStyle[] = [
  {
    category_key: 'resolvedCases',
    category_name: 'Number of Cases',
    category_name_format: 'Number of Cases',
    colors: YellowDistributionColor,
    status: true
  }
];
