import React, { FC, useEffect, useState } from 'react';
import './Agent.scss';
import { PageContainer } from '../../../components/common/blocks/PageContainer';
import { Loader } from '../../../components/common/blocks/Loader';
import { endOfDay, startOfDay } from 'date-fns';
import { DateRangePicker } from '../../incidents/date-picker-component/DatePickerComponent';
import { logEventWithUserInfo } from '../../../utils/amplitude';
import { Stack, Text } from '../../../components/common';
import { AgentDistributionChart } from '../components/AgentDistributionChart';
import { useHistory, useLocation } from 'react-router-dom';
import AccountSearch from '../../customerHealth/components/AccountSearch';
import { AgentMetrics } from './AgentMetrics';
import { AgentDistribution } from './AgentDistribution';
import { AgentTable } from './AgentTable';
import Constants, {
  DEFAULT_END_DATE,
  DEFAULT_START_DATE
} from '../../../utils/constants';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';
import { useDateRangeQueryForAgentData } from '../utils';
import { useResetPage } from '../../../hooks/useResetPage';
import { TablePaper } from '../../../components/common/blocks/TablePaper';

export const AgentDetails: FC = () => {
  const location = useLocation();
  const [dateRange, setDateRange] = useState<[Date, Date]>([
    DEFAULT_START_DATE,
    DEFAULT_END_DATE
  ]);
  const agentId =
    new URLSearchParams(location.search).get(Constants.AGENT_ID_SEARCH_PARAM) ||
    '';
  const [agentName, setAgentName] = useState('');

  const history = useHistory();

  const { data: agentAnalyticsData } = useDateRangeQueryForAgentData(dateRange);

  const handleDateRangeChange = (date: [Date, Date]) => {
    logEventWithUserInfo('Agent Date Filter Change', {
      startDate: date[0].toString(),
      endDate: date[1].toString()
    }).then();
    setDateRange([startOfDay(date[0]), endOfDay(date[1])]);
  };

  const handleSelectOption = (optionSelected: any) => {
    history.replace(
      `${Constants.routes.AGENT_DETAILS}?${Constants.AGENT_ID_SEARCH_PARAM}=${optionSelected}`
    );
  };

  const redirectOverviewAgent = () => {
    history.push(Constants.routes.AGENT_OVERVIEW);
  };

  useResetPage();

  useEffect(() => {
    logEventWithUserInfo('Agent Detail View');
  }, []);

  const BackPage = () => {
    return (
      <Stack gap={10} align="center" role="button" tabIndex={0}>
        <div className="agent-back-container" onClick={redirectOverviewAgent}>
          <ArrowBackOutlinedIcon className="agent-button" />
        </div>
        <Text variant="p4" weight="semi-bolder" color="dark">
          Back to agent overview
        </Text>
      </Stack>
    );
  };

  const WithAgent = () => {
    return (
      <div className="manage-user-container">
        <TablePaper>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              paddingBottom: '20px',
              paddingTop: 16
            }}
          >
            <div
              style={{
                marginBottom: '3%',
                display: 'flex',
                alignItems: 'center'
              }}
            >
              <img
                src="/images/arrow_left.png"
                alt="users"
                style={{ cursor: 'pointer' }}
                onClick={redirectOverviewAgent}
              />
              <div
                style={{
                  fontFamily: 'DM Sans',
                  fontStyle: 'normal',
                  fontWeight: 'bold',
                  fontSize: 24,
                  marginLeft: '4%',
                  marginRight: '-4%',
                  textAlign: 'left'
                }}
              >
                Back to agent overview
              </div>
            </div>

            <DateRangePicker
              value={dateRange}
              onChange={handleDateRangeChange}
            />
          </div>

          <AgentMetrics
            dateRange={dateRange}
            agentId={agentId}
            setAgentName={setAgentName}
          />
          {/* ----------------------Here goes the graphs ------------------------------------ */}
          {/* Line Graph */}
          <AgentDistribution
            heading="Number of cases on daily basis"
            subHeading="Last 1 month Data"
            agentId={agentId}
            waitFor={agentAnalyticsData}
          />
          {/* Pie Chart */}
          <AgentDistributionChart
            agentId={agentId}
            waitFor={agentAnalyticsData}
          />
          {/* ---------------------- End of the graphs ------------------------------------ */}
          <AgentTable
            heading="Cases"
            agentId={agentId}
            waitFor={agentAnalyticsData}
          />
          <Stack
            direction="horizontal"
            justify="flex-start"
            gap={20}
            align="center"
            style={{ width: 'minmax(302px, 1fr)', paddingBottom: '30px' }}
          ></Stack>
        </TablePaper>
      </div>
    );
  };

  const WithoutAgent = () => {
    const messageFirst =
      'Currently, you haven’t selected any agent to see details.';
    const messageLast =
      'Please search and select from right side a agent to view.';
    return (
      <div className="agent-view-container">
        <div className="agent-view-img"></div>
        <div className="container-agent-message-text">
          <div className="agent-message-text">{messageFirst}</div>
          <div className="agent-message-text">{messageLast}</div>
        </div>
      </div>
    );
  };

  return (
    <div className="agent-container">
      <div className="agent-content">
        <PageContainer>
          {agentId !== '' ? (
            <WithAgent />
          ) : (
            <>
              <BackPage />
              <WithoutAgent />
            </>
          )}
        </PageContainer>
      </div>
      <div className="agent-searchBar">
        <div className="agent-searchBar-content">
          {agentAnalyticsData === undefined ? (
            <Loader />
          ) : (
            <AccountSearch
              defaultSelection={agentName}
              isCustom={true}
              deactivateDropDown={true}
              placeholder="Search by Agent"
              deactivateTitle={true}
              withoutBorder={true}
              dataListSearch={agentAnalyticsData?.agents}
              showListSearch={true}
              redirectWithId={true}
              getClickCardMetrics={handleSelectOption}
            />
          )}
        </div>
      </div>
    </div>
  );
};
