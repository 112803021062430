import { CaseSentiment } from '../customerHealth/types';
import { UniqueIdentifier } from '@dnd-kit/core';
import { BaseItem } from '../../components/common/sortable-list/SortableList';

export type UserType = {
  id: number;
  name: string;
  email: string;
  status: string;
  lastDateActive: string;
  roles: string[];
};

export type CaseType = {
  id: string;
  Id: string;
  AgentName: string;
  CaseNumber: string;
  caseSentiment: CaseSentiment;
  Category: string[];
  Description: string;
  InstanceURL: string;
  secondaryCategories?: string[];
  sourceName: string;
  Status: string;
  Subject: string;
  timeOfCreation: string;
};

export type CategoryDataWithFilter = {
  filter: string;
  caseCategoriesData: Record<string, CategoryData>;
};

export type CategoryData = {
  bins: Record<string, number>;
  count: number;
  averageNumberOfComments: number;
  recentCases: CaseType[];
  escalatedCases: CaseType[];
  mttr: {
    mttr: number | null;
    numberOfClosedCases: number;
  };
  csat: {
    score: number | null;
    totalSurveyCount: number | null;
  };
  casesChangePercent: number | null;
  mttrChangePercent: number | null;
  csatChangePercent: number | null;
  escalatedCasesChangePercent: number | null;
  sentimentScoreChangePercent: number | null;
  // Null is when, we haven't yet calculated the score for any case for this category
  averageSentimentScore: number | null;
  secondaryCategoryDistribution: Record<string, number>;
  revenueImpacted: number;
  automationTriggerDetails: CategoryAutomationTriggerDetails | null;
};

export type CategoryAutomationTriggerDetails = {
  exists: boolean;
  triggerId: string | null;
  numberOfTicketsAutoResponded: number | null;
};

export type CaseCategoriesType = {
  name: string;
} & CategoryData;

export enum CategoryFilterType {
  SELECT = 'select'
}

export interface FilterChecked extends BaseItem {
  id: UniqueIdentifier;
  value: string;
  isChecked: boolean;
}

export type CategoryFilter = {
  type: CategoryFilterType;
  label: string;
  key: string;
  values: FilterChecked[];
};

export type CategoryBar = {
  name: string;
  count: number;
  color: string;
};
