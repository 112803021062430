import React, { ReactElement } from 'react';

import { RulesRowManager } from '../../../utils/RulesRowManager';

import { HandleRowComponentType, Option } from '../../../types';
import { ReactComponent as TagSvg } from '../../../../../assets/tag.svg';
import '../styles.scss';
import { useTriggersPrerequisites } from '../../../utils';
import SearchWidget from '../../../../categories/components/SearchWidget';
import _ from 'lodash';

/**
 * Row manager for Writing Custom Fields
 */
export class WriteCaseCustomFieldRowManager extends RulesRowManager {
  constructor(
    options: Option[],
    actionValue: any,
    actionOperator: any,
    type: string
  ) {
    super(options, actionValue, actionOperator, type);
    this.action.type = 'write_case_custom_field';
  }
  setDefaultActionValue(): void {
    this.action.value = {};
  }
  createItems(handleRowComponent: HandleRowComponentType): ReactElement {
    const prerequesitesData = useTriggersPrerequisites();
    let customFieldOptions =
      prerequesitesData.triggersPrerequesites?.customFieldDetails || {};
    customFieldOptions = _.entries(customFieldOptions).map(
      ([customFieldName, customFieldValues]) => {
        return {
          customFieldName: customFieldName,
          customFieldValues: customFieldValues
        };
      }
    );

    let customFieldNameSearchVal = '';
    let customFieldValueSearchVal = '';
    if (this.action.value?.customFieldName) {
      const customFieldObj = _.find(customFieldOptions, (opt) =>
        _.isEqual(opt.customFieldName, this.action.value?.customFieldName)
      );
      if (customFieldObj) {
        customFieldNameSearchVal = customFieldObj.customFieldName;
      }

      if (this.action.value?.customFieldValue) {
        customFieldValueSearchVal = this.action.value.customFieldValue;
      }
    }

    return (
      <div className={'d-flex flex-column w-100'}>
        <div className={'d-flex flex-row'}>
          <TagSvg />
          <p className="title">Assign value to a custom field for a case</p>
        </div>
        <div className={'d-flex flex-row'} style={{ marginTop: 8 }}>
          <p className="operator-text">Custom field name: </p>
          <div
            style={{
              flexBasis: '35%'
            }}
          >
            <SearchWidget
              isCustom={true}
              searchValue={customFieldNameSearchVal}
              dataList={_.map(customFieldOptions, (opt) => opt.customFieldName)}
              onInputChange={(input: string) => {
                const customFieldObj = _.find(customFieldOptions, (opt) =>
                  _.isEqual(opt.customFieldName, input)
                );
                if (customFieldObj) {
                  this.action.value = {
                    customFieldName: customFieldObj.customFieldName
                  };
                } else {
                  this.action.value = {};
                }
                customFieldNameSearchVal = input;
                handleRowComponent(this.action);
              }}
            />
          </div>
          <p className="operator-text" style={{ paddingLeft: '10px' }}>
            Custom field value:{' '}
          </p>
          <div
            style={{
              flexBasis: '35%'
            }}
          >
            <SearchWidget
              isCustom={true}
              searchValue={customFieldValueSearchVal}
              dataList={
                this.action.value?.customFieldName
                  ? _.find(customFieldOptions, (opt) =>
                      _.isEqual(
                        opt.customFieldName,
                        this.action.value.customFieldName
                      )
                    )?.customFieldValues || []
                  : []
              }
              onInputChange={(input: string) => {
                const customFieldObj = _.find(customFieldOptions, (opt) =>
                  _.isEqual(
                    opt.customFieldName,
                    this.action.value.customFieldName
                  )
                );
                if (customFieldObj) {
                  this.action.value.customFieldValue = input;
                  customFieldValueSearchVal = this.action.value
                    .customFieldValue;
                } else {
                  this.action.value = {};
                }
                handleRowComponent(this.action);
              }}
            />
          </div>
        </div>
      </div>
    );
  }
}
