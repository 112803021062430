import React, { FC } from 'react';
import { Text } from '../common';

export const NoCategoriesFoundCmp: FC = (props) => {
  return (
    <Text style={{ textAlign: 'center' }}>
      Please schedule an{' '}
      <a
        href="https://calendly.com/palak-iris/irisagent-setup"
        target="_blank"
        rel="noreferrer noopener"
        style={{ color: '#0000EE', textDecoration: 'underline' }}
      >
        onboarding call
      </a>{' '}
      to set up categories and automatic tagging
    </Text>
  );
};
