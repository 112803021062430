import Utils from '../../utils/utils';
import MockData from '../../utils/mockData';
import {
  AccountHealth,
  CustomerHealthDataExtras,
  CustomerHealthDataV2,
  RecentCasesForAccount
} from '../../routes/customerHealth/types';
import { handleError, isMocKData } from './utils';
import {
  AtlassianConfigResponse,
  Customer,
  AtlassianConfigUpdateRequest
} from '../types';
import _ from 'lodash';

export const getAllAccountNames = async () => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getAllAccountNames();
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/account/data/?required=allAccountNames&&sourceName=${user.sourceName}&&organizationId=${user.sourceOrganizationId}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    }
  );
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error('Unhandled status code');
  }

  const body: { allAccountNames: string[] } = await response.json();
  return body;
};

export const getDetailsAccountDashboardIrisPortal = async (
  accountName: string
) => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getDetailsAccountDashboardMockData();
  }

  const url =
    `${process.env.REACT_APP_BACKEND_URL}/account/data/?` +
    new URLSearchParams({
      required: 'accountDetails',
      accountName: accountName.toString(),
      sourceName: user.sourceName.toString(),
      organizationId: user.sourceOrganizationId.toString(),
      isIrisPortal: 'true'
    });
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });
  handleError(response.status);

  const body = await response.json();
  if (response.status !== 200) throw Error(body.message);
  return body;
};

export const getRecentCasesForAccount = async (
  accountName: string,
  count: number
) => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getDetailsAccountDashboardMockData() as RecentCasesForAccount;
  }

  const url =
    `${process.env.REACT_APP_BACKEND_URL}/account/data/?` +
    new URLSearchParams({
      required: 'accountDetails',
      accountName: accountName.toString(),
      sourceName: user.sourceName.toString(),
      organizationId: user.sourceOrganizationId.toString(),
      count: count.toString()
    });
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error('Unhandled status code');
  }

  const body: RecentCasesForAccount = await response.json();
  return body;
};

export const getAccountHealth = async (accountName: string) => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getAccountHealth() as AccountHealth;
  }

  const url =
    `${process.env.REACT_APP_BACKEND_URL}/account/health/?` +
    new URLSearchParams({
      required: 'accountDetails',
      accountName: accountName.toString(),
      customerId: user.customerId
    });
  const response = await fetch(url, {
    headers: new Headers({
      Authorization: `Bearer ${tokenExpress}`
    })
  });
  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error('Unhandled status code');
  }

  const body: AccountHealth = await response.json();
  return body;
};

export const getCustomerHealthDataV2 = async () => {
  const token_express = Utils.getObjectItem('token_express');

  if (isMocKData) {
    return MockData.getCustomerHealthsMockData();
  }

  let endpoint = `${process.env.REACT_APP_APISERVER_URL}/v1/customer/health`;

  const response = await fetch(endpoint, {
    headers: new Headers({
      Authorization: `Bearer ${token_express}`
    })
  });

  handleError(response.status);

  if (response.status === 403) {
    return;
  }

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const body: CustomerHealthDataV2 = await response.json();

  return body;
};

export const getCustomerHealthDataExtras = async () => {
  const token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getCustomerHealthsMockData();
  }

  const response = await fetch(
    `${process.env.REACT_APP_APISERVER_URL}/v1/customer/healthExtras`,
    {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    }
  );

  handleError(response.status);
  if (response.status === 403) {
    return;
  }
  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  const body: CustomerHealthDataExtras = await response.json();

  return body;
};

export const getCustomer = async (): Promise<Customer> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getCustomerMockData();
  }

  if (_.isEmpty(token_express)) {
    handleError(403);
    throw new Error('UnAuthorized!');
  }

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/organization?customerId=${user.customerId}`,
    {
      headers: new Headers({
        Authorization: `Bearer ${token_express}`
      })
    }
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  return await response.json();
};

export const getCustomerEngagement = async () => {
  return MockData.getCustomerEngagementMockData();
};

export const updatePrivateCommentSidebarSettings = async (
  data: any
): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/privateCommentSidebar?customerId=${user.customerId}`,
    {
      method: 'PUT',
      body: JSON.stringify(data),
      headers: new Headers({
        Authorization: `Bearer ${token_express}`,
        'Content-Type': 'application/json'
      })
    } as RequestInit
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};

export const updateAgentAssistSettings = async (
  sections: string[]
): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const body = {
    sections
  };

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/agentAssist?customerId=${user.customerId}`,
    {
      method: 'PUT',
      body: JSON.stringify(body),
      headers: new Headers({
        Authorization: `Bearer ${token_express}`,
        'Content-Type': 'application/json'
      })
    } as RequestInit
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};

export const getAtlassianConfig = async (): Promise<AtlassianConfigResponse> => {
  if (isMocKData) {
    return MockData.getAtlassianConfigMockData();
  }

  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/atlassian/config?customerId=${user.customerId}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token_express}`,
        'Content-Type': 'application/json'
      })
    } as RequestInit
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }

  return await response.json();
};

export const updateAtlassianConfig = async (
  request: AtlassianConfigUpdateRequest
): Promise<void> => {
  let user = Utils.getObjectItem('user');
  let token_express = Utils.getObjectItem('token_express');

  const response = await fetch(
    `${process.env.REACT_APP_BACKEND_URL}/atlassian/config?customerId=${user.customerId}`,
    {
      method: 'PATCH',
      body: JSON.stringify(request),
      headers: new Headers({
        Authorization: `Bearer ${token_express}`,
        'Content-Type': 'application/json'
      })
    } as RequestInit
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(`Unhandled error status code!: ${response.status}`);
  }
};
