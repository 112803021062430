import Utils from '../../utils/utils';
import { handleError, isMocKData } from './utils';
import MockData from '../../utils/mockData';
import qs from 'query-string';
import {
  ArticleMetricEntityType,
  ArticleMetricsData
} from '../../types/automation';

export const getArticleMetrics = async (
  startDate: Date,
  endDate: Date,
  entityType: ArticleMetricEntityType,
  categories: string[] = []
): Promise<ArticleMetricsData> => {
  const user = Utils.getObjectItem('user');
  const tokenExpress = Utils.getObjectItem('token_express');
  if (isMocKData) {
    return MockData.getArticleMetricsData();
  }

  const params = qs.stringify({
    customerId: user.customerId,
    startTime: Utils.formatDateToUTC(startDate),
    endTime: Utils.formatDateToUTC(endDate),
    entityType,
    categories
  });

  const response = await fetch(
    `${process.env.REACT_APP_APISERVER_URL}/v1/customer/impact/articles?${params}`,
    {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${tokenExpress}`
      })
    }
  );
  handleError(response.status);

  if (!response.ok) {
    throw new Error(
      `getArticleMetrics: Unhandled error status code!: ${response.status}`
    );
  }

  return response.json();
};
